import React from "react";

import { avatarImg } from "../../../assets/images";
import { useTotalPrice } from "../../../hooks";
import { formatBalance } from "../../../utils";

export default function SingleUserHeader({ user }) {
    const totalBalance = useTotalPrice(user?.balances, "balance");

    return (
        <div className="flex items-start gap-[20px] justify-between mb-6">
            <div className="flex items-center gap-[15px]">
                <div className="w-[50px] min-w-[50px] h-[50px] min-h-[50px] rounded-full overflow-hidden bg-[#fff]">
                    <img
                        src={
                            user?.avatar
                                ? process.env.REACT_APP_SERVER_URL +
                                  user?.avatar
                                : avatarImg
                        }
                        alt=""
                        className="w-[100%] h-[100%] object-cover"
                    />
                </div>
                <div>
                    <h2 className="font-medium">{user?.name}</h2>
                    <span className="text-darkGrayColor font-medium text-sm">
                        @{user?.username}
                    </span>
                </div>
            </div>
            <div className="flex items-center gap-[20px]">
                <span className="block font-medium text-darkGrayColor text-sm">
                    {user?.country?.countryName}
                </span>
                <span className="block font-medium">$ {formatBalance(totalBalance)}</span>
            </div>
        </div>
    );
}
