import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AdminsTable from "../features/AdminsListPage/components/AdminsTable";
import axios from "../axios";
import { setAllAdmins } from "../redux/slices/adminSlice";
import { AddAdminModal } from "../features/AdminsListPage";
import { Loader } from "../components";

export default function AdminsListPage() {
    const [isAddAdminModalOpen, setIsAddAdminModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const { jwtToken } = useSelector((state) => state.admin);

    const fetchAdmins = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get("/admin/auth/all", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            dispatch(setAllAdmins(data));
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchAdmins();
    }, [fetchAdmins]);

    return (
        <div>
            <div className="flex items-center gap-[10px] justify-between mb-5">
                <h1 className="font-[600] text-lg">Admins</h1>
                <button onClick={() => setIsAddAdminModalOpen(true)}>
                    + Add Admin
                </button>
            </div>

            {isLoading ? (
                <div className="flex justify-center">
                    <Loader />
                </div>
            ) : (
                <div>
                    <AdminsTable />
                </div>
            )}

            <AddAdminModal
                isAddAdminModalOpen={isAddAdminModalOpen}
                setIsAddAdminModalOpen={setIsAddAdminModalOpen}
            />
        </div>
    );
}
