import React, { useEffect, useState } from "react";
import { PresaleListTable } from "../features/Presale";
import { Loader, Pagination } from "../components";
import axios from "../axios";
import { useSelector } from "react-redux";

function PresalePageList() {
  const [presales, setPresales] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    name: "",
    totalPresale: 0,
  });

  const { jwtToken } = useSelector((state) => state.admin);

  const fetchPresales = async () => {
    try {
      const res = await axios.get(
        `/admin/presale/all?name=${filters.name}&limit=${filters.limit}&skip=${filters.skip}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      setPresales(res.data.presales);
      setFilters({
        ...filters,
        totalPresale: res?.data?.total,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPresales();
  }, []);

  return (
    <div>
      <div className="mb-5">
        <h1 className="font-[600] text-lg">Presale List</h1>
      </div>

      <div className="flex items-center gap-[10px] bg-darkCardBgColor p-5 rounded mt-6">
        <input
          name="name"
          onChange={(e) => {
            setFilters({
              ...filters,
              [e.target.name]: e.target.value.trim(),
            });
          }}
          type="text"
          className="h-[45px]"
          placeholder="Search User..."
        />
        <button
          onClick={() => {
            fetchPresales();
          }}
          className="w-[200px] h-[45px]"
        >
          Search
        </button>
      </div>

      {isLoading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        <div className="mt-6">
          <PresaleListTable presales={presales} />
          <Pagination
            skip={filters.skip}
            limit={filters.limit}
            total={filters.totalPresale}
            incOrDecSkip={(number) => filters.skip(number)}
            updateSkip={(skip) => filters.skip(skip)}
          />
        </div>
      )}
    </div>
  );
}

export default PresalePageList;
