import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Loader, TransactionsTable } from "../components";
import { TransactionsFilters } from "../features/TransactionsListPage";
import {
    clearTxnFilters,
    fetchTransactions,
} from "../redux/slices/transactionsSlice";

export default function TransactionsListPage() {
    const dispatch = useDispatch();
    const { skip, isLoading } = useSelector((state) => state.transactions);

    useEffect(() => {
        dispatch(fetchTransactions());
    }, [dispatch, skip]);

    useEffect(() => {
        return () => {
            dispatch(clearTxnFilters());
        };
    }, []);

    return (
        <div>
            <div className="mb-5">
                <h1 className="font-[600] text-lg">Transactions List</h1>
            </div>

            {isLoading ? (
                <div className="flex justify-center">
                    <Loader />
                </div>
            ) : (
                <>
                    <div>
                        <TransactionsFilters />
                    </div>

                    <div className="mt-6">
                        <TransactionsTable />
                    </div>
                </>
            )}
        </div>
    );
}
