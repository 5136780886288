import React from "react";

export default function TopSmallCard({ icon, title, value }) {
    return (
        <div className="bg-darkCardBgColor p-4 rounded flex items-center gap-[15px] ">
            <div className="w-[35px] h-[35px] min-w-[35px] min-h-[35px] text-xl rounded-full bg-primaryColor flex items-center justify-center text-[#fff]">
                {icon}
            </div>
            <div>
                <span className="block text-darkGrayColor text-sm">
                    {title}
                </span>
                <span className="block font-[600] text-lg mt-1">{value}</span>
            </div>
        </div>
    );
}
