import React from "react";

export default function NetworksTable({ networks }) {
    return (
        <div className="overflow-x-auto border border-[#333] rounded">
            <table className="w-full bg-darkCardBgColor text-darkGrayColor text-[14px]">
                <thead className="text-left">
                    <tr>
                        <th className="font-[600] px-4 py-3">Network Name</th>
                        <th className="font-[600] px-4 py-3">Token Standard</th>
                        <th className="font-[600] px-4 py-3">Network VM</th>
                        <th className="font-[600] px-4 py-3">RPC URL</th>
                    </tr>
                </thead>
                <tbody>
                    {networks?.map((network, index) => {
                        return (
                            <tr
                                key={index}
                                className="border-t border-[#24262d]"
                            >
                                <td className="px-4 py-3">{network?.name}</td>
                                <td className="px-4 py-3">
                                    {network?.tokenStandard}
                                </td>
                                <td className="px-4 py-3">
                                    {network?.networkVM}
                                </td>
                                <td className="px-4 py-3">{network?.rpcUrl || "NA"}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
