import React from "react";
import { useNavigate } from "react-router-dom";

import { avatarImg } from "../../../assets/images";
import { useTotalPrice } from "../../../hooks";
import { formatBalance } from "../../../utils";

export default function SingleUsersTableRow({ user }) {
    const totalBalance = useTotalPrice(user?.balances, "balance");
    const navigate = useNavigate();

    return (
        <tr
            className="border-t border-[#24262d] cursor-pointer hover:bg-[#22252b]"
            onClick={() => navigate(`/users/${user?._id}`)}
        >
            <td className="px-4 py-3">#{user?._id?.slice(0, 6)}</td>
            <td className="px-4 py-3">
                <div className="flex items-center gap-[12px]">
                    <div className="w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-full overflow-hidden">
                        <img
                            src={
                                user?.avatar
                                    ? process.env?.REACT_APP_SERVER_URL +
                                      user?.avatar
                                    : avatarImg
                            }
                            alt=""
                            className="w-[100%] h-[100%] object-cover"
                        />
                    </div>
                    <div>
                        <span className="block">{user?.name}</span>
                        <span className="block">@{user?.username}</span>
                    </div>
                </div>
            </td>
            <td className="px-4 py-3 capitalize">{user?.type}</td>
            <td className="px-4 py-3 capitalize">
                {user?.country?.countryName}
            </td>
            <td className="px-4 py-3">${formatBalance(totalBalance)}</td>
            <td className="px-4 py-3"></td>
        </tr>
    );
}
