import Sidebar from "./Sidebar";
import Header from "./Header";
import TransactionsTable from "./TransactionsTable";
import InfoCard from "./InfoCard";
import Pagination from "./Pagination";
import Loader from "./Loader";
import MainLoader from "./MainLoader";

export {
    Sidebar,
    Header,
    TransactionsTable,
    InfoCard,
    Pagination,
    Loader,
    MainLoader,
};
