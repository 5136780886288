import React, { useState, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useHandleClickOutside } from "../../../hooks";
import axios from "../../../axios";

export default function AddNewStateModal({
    isStateAddModalOpen,
    setIsStateAddModalOpen,
    setStates,
}) {
    const [data, setData] = useState({
        stateName: "",
        isocode: "",
        latitude: "",
        longitude: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const wrapperRef = useRef();
    const { isocode } = useParams();
    const { jwtToken } = useSelector((state) => state.admin);
    useHandleClickOutside(wrapperRef, () => setIsStateAddModalOpen(false));

    const handleChange = (e) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            setError("");

            const response = await axios.post(
                `/admin/states/new/${isocode}`,
                data,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            setStates((prev) => {
                return [...prev, response.data];
            });
            setData({
                stateName: "",
                isocode: "",
                latitude: "",
                longitude: "",
            });
            setIsLoading(false);
            setIsStateAddModalOpen(false);
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong,Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div
            className={
                "fixed top-0 left-0 bg-[#000] w-full h-full z-10 transition-all " +
                (isStateAddModalOpen
                    ? "visible bg-[#0004]"
                    : "hidden bg-[#0000]")
            }
        >
            <form
                className={
                    "fixed top-0 right-0 flex flex-col bg-darkCardBgColor w-[580px] max-w-[100%] h-full z-10 flex-colum transition-all " +
                    (isStateAddModalOpen
                        ? "translate-x-0"
                        : "translate-x-[100%]")
                }
                onSubmit={handleSubmit}
                ref={wrapperRef}
            >
                <div className="flex items-center justify-between p-5">
                    <div className="text-[#d5d6d7]">
                        <h4 className="font-[600] text-lg">Add State</h4>
                        <p className="text-sm text-darkGrayColor mt-[2px]">
                            Add state and necessary information here
                        </p>
                    </div>
                    <button
                        type="button"
                        className="bg-[#fff] text-[#f05252] w-[25px] h-[25px] rounded-full text-base flex items-center justify-center p-0 hover:bg-[#ffe1e1]"
                        onClick={() => {
                            setIsStateAddModalOpen(false);
                        }}
                    >
                        <IoClose />
                    </button>
                </div>
                <div className="flex-1 overflow-y-auto p-5 bg-[#24262d]">
                    <div className="">
                        <label htmlFor="">State Name</label>
                        <input
                            type="text"
                            placeholder="Ex: New York"
                            name="stateName"
                            value={data.stateName || ""}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">ISO Code</label>
                        <input
                            type="text"
                            placeholder="Ex: US-NY"
                            name="isocode"
                            onChange={handleChange}
                            value={data.isocode || ""}
                            required
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">Latitude</label>
                        <input
                            type="text"
                            placeholder="Enter Latitude"
                            name="latitude"
                            onChange={handleChange}
                            value={data.latitude || ""}
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">Longitude</label>
                        <input
                            type="text"
                            placeholder="Enter Longitude"
                            name="longitude"
                            onChange={handleChange}
                            value={data.longitude || ""}
                        />
                    </div>

                    {error && (
                        <span className="block text-sm text-red-500 mt-4">
                            {error}
                        </span>
                    )}
                </div>

                <div className="flex items-center gap-[1em] p-5">
                    <button
                        type="button"
                        className="w-full text-[#707275] bg-[#24262d] transition-all hover:bg-transparent border border-transparent hover:border-[#24262d] hover:text-red-500"
                        onClick={() => {
                            setIsStateAddModalOpen(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button className="w-full" disabled={isLoading}>
                        Add State
                    </button>
                </div>
            </form>
        </div>
    );
}
