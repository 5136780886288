import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "../axios";
import { AddCountryModal, CountriesTable } from "../features/CountriesPage";
import { InfoCard, Loader } from "../components";
import { setCountries } from "../redux/slices/countriesSlice";

export default function CountriesPage() {
    const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.admin);
    const { totalCountries, totalStates, activeCountries } = useSelector(
        (state) => state.countries
    );
    const dispatch = useDispatch();

    const fetchCountries = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get("/admin/countries/all", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            dispatch(setCountries(data));
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [dispatch, jwtToken]);

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

    return (
        <div>
            <div className="flex items-center gap-[10px] justify-between mb-5">
                <h1 className="font-[600] text-lg">Countries</h1>
                <button onClick={() => setIsCountryModalOpen(true)}>
                    + Add Country
                </button>
            </div>

            {isLoading ? (
                <div className="flex justify-center">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-4 gap-6 mb-6">
                        <InfoCard
                            title="Total Countries"
                            value={totalCountries}
                        />
                        <InfoCard
                            title="Active Countries"
                            value={activeCountries}
                        />
                        <InfoCard title="Total States" value={totalStates} />
                    </div>

                    <div className="flex items-center gap-[10px] bg-darkCardBgColor p-5 rounded mt-6">
                        <input
                            type="text"
                            className="h-[45px]"
                            placeholder="Search Country..."
                        />
                        <button className="w-[200px] h-[45px]">Search</button>
                    </div>

                    <div className="mt-6">
                        <CountriesTable />
                    </div>
                </>
            )}

            <AddCountryModal
                isCountryModalOpen={isCountryModalOpen}
                setIsCountryModalOpen={setIsCountryModalOpen}
            />
        </div>
    );
}
