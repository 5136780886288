import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: "",
  admin: {},
  jwtToken: localStorage.getItem("random-string") || "",
  admins: [],
  avatar: "",
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdmin: (state, action) => {
      state.admin = action.payload?.admin;
      state.jwtToken = action.payload?.jwtToken;
      state.isLoggedIn = true;
      if (action.payload?.admin?.avatar) {
        state.avatar = action.payload?.admin?.avatar;
      }

      localStorage.setItem("random-string", action.payload?.jwtToken);
    },
    setAdminAfterReload: (state, action) => {
      state.admin = action.payload;
      state.isLoggedIn = true;
    },
    setAllAdmins: (state, action) => {
      state.admins = action.payload;
    },
    addNewAdmins: (state, action) => {
      state.admins.push(action.payload);
    },
    deleteAdmin: (state, action) => {
      const filteredAdmins = state.admins?.filter((admin) => {
        return admin?._id !== action.payload;
      });
      state.admins = filteredAdmins;
    },
    logoutAdmin: (state, action) => {
      state.admin = {};
      state.jwtToken = "";
      state.isLoggedIn = false;

      localStorage.removeItem("random-string");
    },
    setAdmAvatar: (state, { payload }) => {
      state.avatar = payload;
    },
    updateAdmData: (state, { payload }) => {
      state.admin[payload.name] = payload.value;
    },
  },
});

export const {
  setAdmin,
  setAllAdmins,
  addNewAdmins,
  deleteAdmin,
  setAdminAfterReload,
  logoutAdmin,
  setAdmAvatar,
  updateAdmData,
} = adminSlice.actions;

export default adminSlice.reducer;
