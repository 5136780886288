import React from "react";
import moment from "moment/moment";

import { useDispatch, useSelector } from "react-redux";
import { avatarImg } from "../assets/images";
import { formatBalance } from "../utils";
import Pagination from "./Pagination";
import { incOrDecSkipTxn, updateTxnSkip } from "../redux/slices/transactionsSlice";

export default function TransactionsTable() {
    const { transactions, limit, skip, totalTransactions } = useSelector(
        (state) => state.transactions
    );
    const dispatch = useDispatch();

    return (
        <div className="bg-darkCardBgColor border border-[#333] rounded">
            <div className="overflow-x-auto">
                <table className="w-full text-darkGrayColor text-[14px]">
                    <thead className="text-left">
                        <tr>
                            <th className="font-[600] px-4 py-3">R.No</th>
                            <th className="font-[600] px-4 py-3">From</th>
                            <th className="font-[600] px-4 py-3">To</th>
                            <th className="font-[600] px-4 py-3">Amount</th>
                            <th className="font-[600] px-4 py-3">Type</th>
                            <th className="font-[600] px-4 py-3">Date</th>
                            <th className="font-[600] px-4 py-3">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions?.map((transaction, index) => {
                            const {
                                transactionId,
                                amount,
                                transactionType,
                                sender,
                                receiver,
                                status,
                                receiverAddress,
                                currency,
                                createdAt,
                            } = transaction;
                            return (
                                <tr
                                    key={index}
                                    className="border-t border-[#24262d]"
                                >
                                    <td className="px-4 py-3">
                                        #{transactionId}
                                    </td>
                                    <td className="px-4 py-3">
                                        {transactionType === "transfer" ||
                                        transactionType === "payment" ||
                                        transactionType === "withdraw" ? (
                                            <div className="flex items-center gap-[12px]">
                                                <div className="w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-full overflow-hidden">
                                                    <img
                                                        src={
                                                            sender?.avatar
                                                                ? process.env
                                                                      .REACT_APP_SERVER_URL +
                                                                  sender?.avatar
                                                                : avatarImg
                                                        }
                                                        alt=""
                                                        className="w-[100%] h-[100%] object-cover"
                                                    />
                                                </div>
                                                <div>
                                                    <span className="block">
                                                        {sender?.name ||
                                                            "No Name"}
                                                    </span>
                                                    <span className="block">
                                                        @
                                                        {sender?.username ||
                                                            "0x0"}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : transactionType === "deposit" ? (
                                            <span>Outside wallet</span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </td>
                                    <td className="px-4 py-3">
                                        {transactionType === "transfer" ||
                                        transactionType === "payment" ||
                                        transactionType === "deposit" ? (
                                            <div className="flex items-center gap-[12px]">
                                                <div className="w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-full overflow-hidden">
                                                    <img
                                                        src={
                                                            receiver?.avatar
                                                                ? process.env
                                                                      .REACT_APP_SERVER_URL +
                                                                  receiver?.avatar
                                                                : avatarImg
                                                        }
                                                        alt=""
                                                        className="w-[100%] h-[100%] object-cover"
                                                    />
                                                </div>
                                                <div>
                                                    <span className="block">
                                                        {receiver?.name ||
                                                            "No Name"}
                                                    </span>
                                                    <span className="block">
                                                        @
                                                        {receiver?.username ||
                                                            "0x0"}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : transactionType === "withdraw" ? (
                                            <span>
                                                {receiverAddress?.slice(0, 6)}
                                                ...
                                                {receiverAddress?.slice(-6)}
                                            </span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </td>
                                    <td className="px-4 py-3">
                                        <div className="flex items-center justify-end sm:justify-start gap-[10px]">
                                            <div className="hidden sm:block w-[17px] h-[17px] min-w-[17px] min-h-[17px] rounded-full overflow-hidden">
                                                <img
                                                    src={
                                                        process.env
                                                            ?.REACT_APP_SERVER_URL +
                                                        currency?.logo
                                                    }
                                                    alt=""
                                                    className="w-[100%] h-[100%] object-cover"
                                                />
                                            </div>
                                            <div className="block text-[15px] flex items-center gap-[6px]">
                                                <span className="font-[500]">
                                                    {formatBalance(amount)}
                                                </span>
                                                <span className="">
                                                    {currency?.symbol}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3">
                                        {transactionType}
                                    </td>
                                    <td className="px-4 py-3 whitespace-nowrap">
                                        {moment(createdAt).fromNow()}
                                    </td>
                                    <td className="px-4 py-3">
                                        <span
                                            className={
                                                "text-[11px] rounded px-[4.5px] py-[2.7px] capitalize " +
                                                (status === "completed"
                                                    ? "bg-[#daf4eb] text-[#34c38f]"
                                                    : status === "failed"
                                                    ? "text-[#f46a6a] bg-[#fde4e4]"
                                                    : "text-gray-500 bg-gray-100")
                                            }
                                        >
                                            {status}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="border-t border-[#24262d] p-4">
                <Pagination
                    skip={skip}
                    limit={limit}
                    incOrDecSkip={(number) => dispatch(incOrDecSkipTxn(number))}
                    total={totalTransactions}
                    updateSkip={(skip) => dispatch(updateTxnSkip(skip))}
                />
            </div>
        </div>
    );
}
