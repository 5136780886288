import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function AuthPrivateRoute({ children, ...rest }) {
    const { isLoggedIn } = useSelector((state) => state.admin);

    if (isLoggedIn) {
        return <Navigate replace to="/" />;
    } else {
        return children;
    }
}
