import React, { useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import { useHandleClickOutside } from "../../../hooks";
import axios from "../../../axios";
import { addNewAdmins } from "../../../redux/slices/adminSlice";
import SuccessLoader from "../../../components/SuccessLoader";
import { toast } from "react-toastify";

export default function AddAdminModal({
  isAddAdminModalOpen,
  setIsAddAdminModalOpen,
}) {
  const [data, setData] = useState({
    email: "",
    password: "",
    role: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { jwtToken } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const wrapperRef = useRef();
  useHandleClickOutside(wrapperRef, () => setIsAddAdminModalOpen(false));

  const handleChange = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setError("");
      setIsLoading(true);

      const response = await axios.post("/admin/auth/new", data, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      dispatch(addNewAdmins(response.data));

      setIsLoading(false);
      setData({ email: "", password: "" });
      setIsAddAdminModalOpen(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.error || "Something went wrong, Try again"
      );
      setError(err?.response?.data?.error || "Something went wrong, Try again");
      setIsLoading(false);
    }
  };

  return (
    <div
      className={
        "fixed top-0 left-0 bg-[#000] w-full h-full z-10 transition-all " +
        (isAddAdminModalOpen ? "visible bg-[#0004]" : "hidden bg-[#0000]")
      }
    >
      <form
        className={
          "fixed top-0 right-0 flex flex-col bg-darkCardBgColor w-[580px] max-w-[100%] h-full z-10 flex-colum transition-all " +
          (isAddAdminModalOpen ? "translate-x-0" : "translate-x-[100%]")
        }
        onSubmit={handleSubmit}
        ref={wrapperRef}
      >
        <div className="flex items-center justify-between p-5">
          <div className="text-[#d5d6d7]">
            <h4 className="font-[600] text-lg">Add Admin</h4>
            <p className="text-sm text-darkGrayColor mt-[2px]">
              Add Admin and necessary information here
            </p>
          </div>
          <button
            type="button"
            className="bg-[#fff] text-[#f05252] w-[25px] h-[25px] rounded-full text-base flex items-center justify-center p-0 hover:bg-[#ffe1e1]"
            onClick={() => {
              setIsAddAdminModalOpen(false);
            }}
          >
            <IoClose />
          </button>
        </div>
        <div className="flex-1 overflow-y-auto p-5 bg-[#24262d]">
          <div className="">
            <label htmlFor="">Email</label>
            <input
              type="email"
              placeholder="Ex: example@gmail.com"
              name="email"
              value={data.email || ""}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mt-4">
            <label htmlFor="">Password</label>
            <input
              type="password"
              placeholder="********"
              name="password"
              onChange={handleChange}
              value={data.password || ""}
              required
            />
          </div>
          <div className="mt-4">
            <label htmlFor="">Role</label>
            <select name="role" onChange={handleChange} id="">
              <option value="">Select Role</option>
              <option value="super-admin">Super Admin</option>
              <option value="admin">Admin</option>
            </select>
          </div>

          <span className="block text-sm text-red-500 mt-4">{error}</span>
        </div>

        <div className="flex items-center gap-[1em] p-5">
          <button
            type="button"
            className="w-full text-[#707275] bg-[#24262d] transition-all hover:bg-transparent border border-transparent hover:border-[#24262d] hover:text-red-500"
            onClick={() => {
              setIsAddAdminModalOpen(false);
            }}
          >
            Cancel
          </button>
          <button className="w-full" disabled={isLoading}>
            Add Admin
          </button>
        </div>
      </form>
      <div>
        <SuccessLoader />
      </div>
    </div>
  );
}
