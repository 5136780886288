import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function SuccessLoader() {
  return (
    <div>
      <ToastContainer />
    </div>
  );
}

export default SuccessLoader;
