import React, { useEffect, useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { NewUsersGraph, UsersCountryList } from "../features/UsersPage";
import axios from "../axios";
import { useSelector } from "react-redux";
import { Loader } from "../components";

export default function UsersPage() {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.admin);

    const fetchUsersDetails = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get("/admin/users/details", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setData(data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchUsersDetails();
    }, [fetchUsersDetails]);

    return (
        <div>
            <div className="mb-5 flex items-center justify-between">
                <h1 className="font-[600] text-lg">Users</h1>
                <Link
                    to="/users-list"
                    className="text-primaryColor text-sm font-medium underline cursor-pointer"
                >
                    Users List
                </Link>
            </div>

            {isLoading ? (
                <div className="flex justify-center">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-4 gap-6">
                        <div className="bg-darkCardBgColor p-4 rounded">
                            <span className="block text-darkGrayColor text-sm">
                                Total Users
                            </span>
                            <span className="block font-[600] text-lg mt-1">
                                {data?.totalUsers || 0}
                            </span>
                        </div>
                        <div className="bg-darkCardBgColor p-4 rounded">
                            <span className="block text-darkGrayColor text-sm">
                                Active Users
                            </span>
                            <span className="block font-[600] text-lg mt-1">
                                {data?.activeUsers || 0}
                            </span>
                        </div>
                        <div className="bg-darkCardBgColor p-4 rounded">
                            <span className="block text-darkGrayColor text-sm">
                                Disabled Users
                            </span>
                            <span className="block font-[600] text-lg mt-1">
                                {data?.disabledUsers || 0}
                            </span>
                        </div>
                        <div className="bg-darkCardBgColor p-4 rounded">
                            <span className="block text-darkGrayColor text-sm">
                                Total Accounts
                            </span>
                            <span className="block font-[600] text-lg mt-1">
                                {data?.totalAccounts || 0}
                            </span>
                        </div>
                    </div>

                    <div className="grid grid-cols-[65%_auto] gap-6 mt-6">
                        <NewUsersGraph />
                        <UsersCountryList data={data?.usersByCountry} />
                    </div>

                    {/* <div className="grid grid-cols-3 gap-6 mt-6">
                <UsersOsList />
                <UsersTopCitiesList />
            </div> */}
                </>
            )}
        </div>
    );
}
