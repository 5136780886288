import layoutReducer from "./layoutSlice";
import adminReducer from "./adminSlice";
import currenciesReducer from "./currenciesSlice";
import transactionsReducer from "./transactionsSlice";
import countriesReducer from "./countriesSlice";

export {
    layoutReducer,
    adminReducer,
    currenciesReducer,
    transactionsReducer,
    countriesReducer,
};
