import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function SuperAdminPrivateRoute({ children, ...rest }) {
    const { isLoggedIn, admin } = useSelector((state) => state.admin);

    if (!isLoggedIn) {
        return <Navigate replace to="/login" />;
    } else if (admin.role !== "super-admin") {
        return <Navigate replace to="/" />;
    } else {
        return children;
    }
}

export default SuperAdminPrivateRoute;
