import React, { useState } from "react";
import { avatarImg } from "../assets/images";
import { RiImageAddFill } from "react-icons/ri";
import axios from "../axios";
import { useSelector, useDispatch } from "react-redux";
import { setAdmAvatar, updateAdmData } from "../redux/slices/adminSlice";
import BtnLoader from "../components/BtnLoader";
import { toast } from "react-toastify";
import SuccessLoader from "../components/SuccessLoader";
export default function SettingsPage() {
  const { jwtToken, admin, avatar } = useSelector((state) => state.admin);
  const [section, setSection] = useState({
    one: true,
    two: false,
    three: false,
  });

  const [avatarImage, setAvatarImage] = useState({});
  const [error, setError] = useState("");
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isPasswordLoader, setIsPasswordLoader] = useState(false);

  const dispatch = useDispatch();

  const handleAvatar = (e) => {
    const file = e.target.files[0];
    setAvatarImage(file);
    const previewUrl = URL.createObjectURL(file);
    dispatch(setAdmAvatar(previewUrl));
  };

  const formData = new FormData();
  formData.append("first_name", admin.first_name);
  formData.append("last_name", admin.last_name);
  formData.append("email", admin.email);
  formData.append("address", admin.address);
  formData.append("avatar", avatarImage);

  const changeProfileDetails = async () => {
    try {
      setIsProfileLoading(true);
      const res = await axios.put(`/admin/auth/update-profile`, formData, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setIsProfileLoading(false);
      toast.success("updated successfully");
    } catch (error) {
      toast.error(error?.response?.data.error || "somthing went wrong");
    }
  };

  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChangePassword = (e) => {
    const { name, value } = e.target;

    setPasswords({
      ...passwords,
      [name]: value,
    });
  };

  const updatePassword = async () => {
    try {
      setIsPasswordLoader(true);
      const res = await axios.post(`/admin/auth/change-password`, passwords, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setIsPasswordLoader(false);
      toast.success(res.data.message);
    } catch (error) {
      setIsPasswordLoader(false);

      toast.error(error?.response?.data?.error || "sothing went wrong");
    }
  };

  return (
    <div>
      <div className="flex gap-12">
        <div className="">
          <div
            onClick={() => {
              setSection({
                one: true,
                two: false,
                three: false,
              });
            }}
            className={`bg-darkCardBgColor cursor-pointer w-96 p-2 mb-4 ${
              section.one ? "bg-darkGrayColor" : ""
            }`}
          >
            <h1 className="text-center text-darkTextColor">Profile</h1>
          </div>
          <div
            onClick={() => {
              setSection({
                one: false,
                two: true,
                three: false,
              });
            }}
            className={`bg-darkCardBgColor w-96 p-2 mb-4 cursor-pointer ${
              section.two ? "bg-darkGrayColor" : ""
            }`}
          >
            <h1 className="text-center text-darkTextColor">Change Password</h1>
          </div>
        </div>
        <div className="bg-darkCardBgColor w-full min-h-max">
          <div className="p-10  ">
            {section.one ? (
              <div className="flex justify-between">
                <div className="py-5 w-full">
                  <div className="relative mb-6 w-28">
                    <img
                      src={
                        avatar
                          ? avatar
                          : admin.avatar
                          ? process.env.REACT_APP_SERVER_URL + admin.avatar
                          : avatarImg
                      }
                      className="rounded-full h-20 w-20"
                      alt="User avatar"
                    />
                    <div className="absolute right-6 bottom-0 ">
                      <input
                        onChange={handleAvatar}
                        type="file"
                        className="opacity-0 absolute h-full w-full cursor-pointer"
                      />
                      <button className="text-xl text-primaryColor bg-black rounded-full p-2 flex items-center">
                        <RiImageAddFill />
                      </button>
                    </div>
                  </div>

                  <div className="grid w-full min-w-max gap-4">
                    <div className="mb-2">
                      <label htmlFor="email1" className="block mb-1">
                        Email Address
                      </label>
                      <input
                        onChange={(e) => {
                          dispatch(
                            updateAdmData({
                              name: e.target.name,
                              value: e.target.value,
                            })
                          );
                        }}
                        value={admin.email || ""}
                        type="email"
                        name="email"
                        id="email1"
                        className="w-full outline-none h-10 px-3 border rounded-lg"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="email1" className="block mb-1">
                        First Name
                      </label>
                      <input
                        onChange={(e) => {
                          dispatch(
                            updateAdmData({
                              name: e.target.name,
                              value: e.target.value,
                            })
                          );
                        }}
                        value={admin.first_name || ""}
                        type="text"
                        name="first_name"
                        id="first_name"
                        className="w-full outline-none h-10 px-3 border rounded-lg"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="email1" className="block mb-1">
                        Last Name
                      </label>
                      <input
                        onChange={(e) => {
                          dispatch(
                            updateAdmData({
                              name: e.target.name,
                              value: e.target.value,
                            })
                          );
                        }}
                        value={admin.last_name || ""}
                        type="text"
                        id="lastName"
                        name="last_name"
                        className="w-full outline-none h-10 px-3 border rounded-lg"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="email1" className="block mb-1">
                        Address
                      </label>
                      <input
                        onChange={(e) => {
                          dispatch(
                            updateAdmData({
                              name: e.target.name,
                              value: e.target.value,
                            })
                          );
                        }}
                        value={admin.address || ""}
                        type="text"
                        id="address"
                        name="address"
                        className="w-full outline-none h-10 px-3 border rounded-lg"
                        placeholder="Full Address"
                      />
                    </div>
                    <div className="flex justify-end">
                      {!isProfileLoading ? (
                        <button
                          onClick={() => {
                            changeProfileDetails();
                          }}
                        >
                          Save Changes
                        </button>
                      ) : (
                        <button className="w-32 ">
                          <BtnLoader />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 w-44">
                  <h1 className="font-bold">Role : </h1>
                  <h1 className="text-sm">{admin.role}</h1>
                </div>
              </div>
            ) : section.two ? (
              <div className="">
                <h1 className="font-bold text-xl">
                  Change Password Credentials
                </h1>
                <div className="py-5">
                  <div className="grid w-full min-w-max gap-4">
                    <div className="mb-2">
                      <label htmlFor="" className="block mb-1">
                        Current Password
                      </label>
                      <input
                        onChange={handleChangePassword}
                        value={passwords.currentPassword}
                        name="currentPassword"
                        type="password"
                        id="currentPassword"
                        className="w-full outline-none h-10 px-3 border rounded-lg"
                        placeholder="Enter Current Password"
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="" className="block mb-1">
                        New Password
                      </label>
                      <input
                        onChange={handleChangePassword}
                        value={passwords.newPassword}
                        name="newPassword"
                        type="password"
                        id="newPasswrod"
                        className="w-full outline-none h-10 px-3 border rounded-lg"
                        placeholder="Enter New Password"
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="email1" className="block mb-1">
                        Confirm New Password
                      </label>
                      <input
                        onChange={handleChangePassword}
                        value={passwords.confirmPassword}
                        name="confirmPassword"
                        type="password"
                        id="confirmPassword"
                        className="w-full outline-none h-10 px-3 border rounded-lg"
                        placeholder="Enter Confirm Password"
                      />
                    </div>

                    <div className="flex justify-end">
                      {!isPasswordLoader ? (
                        <button
                          onClick={() => {
                            updatePassword();
                          }}
                        >
                          Save Changes
                        </button>
                      ) : (
                        <button className="w-32">
                          <BtnLoader />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <h1>Configurations</h1>
              </div>
            )}
          </div>
        </div>
        <div>
          <SuccessLoader />
        </div>
      </div>
    </div>
  );
}
