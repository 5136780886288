import React from "react";

import { ReportsTable } from "../features/ReportsPage";

export default function ReportsPage() {
    return (
        <div>
            <div className="mb-5">
                <h1 className="font-[600] text-lg">Reports</h1>
            </div>

            <div className="flex items-center gap-[10px] bg-darkCardBgColor p-5 rounded mb-6">
                <input
                    type="text"
                    className="h-[45px]"
                    placeholder="Search here..."
                />
                <button className="w-[200px] h-[45px]">Search</button>
            </div>

            <div>
                <ReportsTable />
            </div>
        </div>
    );
}
