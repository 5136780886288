import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clearTxnFilters,
    fetchTransactions,
    updateIsTxnLoading,
    updateTxnFilters,
    updateTxnSort,
} from "../../../redux/slices/transactionsSlice";

export default function TransactionsFilters({ setIsFiltersModalOpen }) {
    const dispatch = useDispatch();

    const { filters, sort, currencies } = useSelector(
        (state) => state.transactions
    );

    const handleFilterChange = (e) => {
        dispatch(
            updateTxnFilters({ name: e.target.name, value: e.target.value })
        );
    };

    return (
        <div className="bg-darkCardBgColor rounded">
            <div className="sm:flex flex-wrap items-end gap-[15px] p-5 h-[100%] overflow-y-auto">
                <div>
                    <label htmlFor="">Reference No</label>
                    <input
                        type="number"
                        placeholder="Reference No"
                        name="referenceNo"
                        onChange={handleFilterChange}
                        className="arrow-hidden"
                        value={filters.referenceNo || ""}
                    />
                </div>
                <div className="mt-4 sm:mt-0">
                    <label htmlFor="">Currency</label>
                    <select
                        name="currency"
                        id=""
                        className="w-[100%] sm:w-[180px]"
                        onChange={handleFilterChange}
                        value={filters.currency || ""}
                    >
                        <option value="all">All</option>
                        {currencies?.map((currency, index) => {
                            return (
                                <option key={index} value={currency?._id}>
                                    {currency?.symbol}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="mt-4 sm:mt-0">
                    <label htmlFor="">Transaction Type</label>
                    <select
                        name="type"
                        id=""
                        className="w-[100%] sm:w-[180px]"
                        onChange={handleFilterChange}
                        value={filters.type || ""}
                    >
                        <option value="all">All</option>
                        <option value="transfer">Transfer</option>
                        <option value="payment">Payment</option>
                        <option value="withdraw">Withdraw</option>
                        <option value="deposit">Deposit</option>
                    </select>
                </div>
                <div className="mt-4 sm:mt-0">
                    <label htmlFor="">Slect Date</label>
                    <select
                        name="date"
                        id=""
                        className="w-[100%] sm:w-[180px]"
                        onChange={handleFilterChange}
                        value={filters.date || ""}
                    >
                        <option value="all">All</option>
                        <option value="7">Last Weak</option>
                        <option value="30">Last Month</option>
                        <option value="360">Last Year</option>
                    </select>
                </div>
                <div className="mt-4 sm:mt-0">
                    <label htmlFor="">Select Status</label>
                    <select
                        name="status"
                        id=""
                        className="w-[100%] sm:w-[180px]"
                        onChange={handleFilterChange}
                        value={filters.status || ""}
                    >
                        <option value="all">All</option>
                        <option value="completed">Completed</option>
                        <option value="pending">Pending</option>
                        <option value="failed">Failed</option>
                    </select>
                </div>
                <div className="mt-4 sm:mt-0">
                    <label htmlFor="">Sort</label>
                    <select
                        name="sort"
                        id=""
                        className="w-[100%] sm:w-[180px]"
                        onChange={(e) => {
                            dispatch(updateTxnSort(e.target.value));
                        }}
                        value={sort || ""}
                    >
                        <option value="default">Default</option>
                        <option value="createdAt:asc">Date (Ascending)</option>
                        <option value="createdAt:desc">
                            Date (Descending)
                        </option>
                    </select>
                </div>
                <div className="mt-4 sm:mt-0">
                    <label htmlFor="">Email / username</label>
                    <input
                        type="text"
                        placeholder="Ex: nihal"
                        name="emailOrUsername"
                        onChange={handleFilterChange}
                        value={filters.emailOrUsername || ""}
                    />
                </div>
                <div className="sm:ml-3 flex items-center justify-end sm:justify-start mt-5 sm:mt-0 gap-3">
                    <button
                        className="border border-[#333] hover:bg-transparent bg-transparent"
                        onClick={() => {
                            dispatch(clearTxnFilters());
                            dispatch(updateIsTxnLoading(true));
                            dispatch(fetchTransactions());
                            setIsFiltersModalOpen(false);
                        }}
                    >
                        Clear
                    </button>
                    <button
                        className="hover:bg-btnHoverColor"
                        onClick={() => {
                            dispatch(updateIsTxnLoading(true));
                            dispatch(fetchTransactions());
                            setIsFiltersModalOpen(false);
                        }}
                    >
                        Search
                    </button>
                </div>
            </div>
        </div>
    );
}
