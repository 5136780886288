import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import TransactionsTable from "../components/TransactionsTable";
import axios from "../axios";
import {
    SingleUserCurrenyCard,
    SingleUserHeader,
} from "../features/SingleUserPage/";
import { InfoCard, Loader } from "../components";
import { useTotalPrice } from "../hooks";
import { formatBalance } from "../utils";
import { useSelector } from "react-redux";

export default function SingleUserPage() {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.admin);
    const { id } = useParams();
    const moneyIn = useTotalPrice(data?.moneyIn, "amount");
    const moneyOut = useTotalPrice(data?.moneyOut, "amount");

    const fetchUserDetails = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get(`/admin/users/single/${id}`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setData(data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchUserDetails();
    }, [fetchUserDetails]);

    if (isLoading) {
        return (
            <div className="flex justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <div>
            <SingleUserHeader user={data?.user} />

            <div className="grid grid-cols-4 gap-6 mb-8">
                <InfoCard
                    title="Total Transactions"
                    value={data?.totalTransactions || 0}
                />
                <InfoCard
                    title="Completed Transactions"
                    value={data?.totalCompletedTransactions || 0}
                />
                <InfoCard
                    title="Money In"
                    value={"$ " + formatBalance(moneyIn)}
                />
                <InfoCard
                    title="Money Out"
                    value={"$ " + formatBalance(moneyOut)}
                />
            </div>

            <div className="flex items-center gap-[15px] flex-wrap">
                {data?.user?.balances?.map((item, index) => {
                    return <SingleUserCurrenyCard key={index} item={item} />;
                })}
            </div>

            <div className="mt-8">
                <h1 className="font-[600] text-base mb-5">
                    Recent Transactions
                </h1>
                <TransactionsTable />
            </div>
        </div>
    );
}
