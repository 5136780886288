import { configureStore } from "@reduxjs/toolkit";
import {
    adminReducer,
    countriesReducer,
    currenciesReducer,
    layoutReducer,
    transactionsReducer,
} from "./slices";

export const store = configureStore({
    reducer: {
        layout: layoutReducer,
        admin: adminReducer,
        currencies: currenciesReducer,
        transactions: transactionsReducer,
        countries: countriesReducer,
    },
});
