import React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

export default function Pagination({
    skip,
    limit,
    total,
    incOrDecSkip,
    updateSkip,
}) {
    return (
        <div className="flex flex-wrap gap-[10px] items-center justify-between">
            <span className="text-[12px] font-medium text-darkGrayColor uppercase">
                Showing {skip * limit + 1} - {(skip + 1) * limit} of {total}
            </span>
            <div className="flex items-center gap-[2px]">
                <button
                    className="bg-transparent text-darkGrayColor hover:bg-[#22252b] disabled:cursor-not-allowed h-[32px] min-w-[32px] px-[10px]"
                    onClick={() => incOrDecSkip(-1)}
                    disabled={skip === 0}
                >
                    <FiChevronLeft />
                </button>
                {Array.from({
                    length: total <= 10 ? 1 : Math.trunc(total / limit),
                }).map((_, index) => {
                    const numberOfButtons = Math.trunc(total / limit);

                    if (
                        numberOfButtons <= 5 ||
                        index === skip ||
                        index === numberOfButtons - 1 ||
                        index === 0 ||
                        skip + 1 === index
                    ) {
                        return (
                            <button
                                className={
                                    "h-[32px] min-w-[32px] px-[10px] " +
                                    (skip === index
                                        ? "font-medium  "
                                        : "bg-transparent text-textColor font-normal hover:bg-[#22252b] ")
                                }
                                key={index}
                                onClick={() => updateSkip(index)}
                            >
                                {index + 1}
                            </button>
                        );
                    }

                    if (
                        (skip + 2 !== numberOfButtons - 1 &&
                            skip + 2 === index) ||
                        (skip - 1 !== 0 && skip - 1 === index)
                    ) {
                        return (
                            <button
                                className="h-[32px] min-w-[32px] px-[10px] font-normal bg-transparent text-textColor hover:bg-[#22252b]  "
                                key={index}
                            >
                                ...
                            </button>
                        );
                    }

                    return <></>;
                })}
                <button
                    className="bg-transparent text-darkGrayColor hover:bg-[#22252b] disabled:cursor-not-allowed h-[32px] min-w-[32px] px-[10px]"
                    onClick={() => incOrDecSkip(1)}
                    disabled={(skip + 1) * limit >= total}
                >
                    <FiChevronRight />
                </button>
            </div>
        </div>
    );
}
