import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
    TransactionsCurrencyGraph,
    TransactionsVolumeGraph,
} from "../features/TransactionsPage";
import axios from "../axios";
import { InfoCard, Loader } from "../components";
import { useTotalPrice } from "../hooks";
import { formatBalance } from "../utils";
import { useSelector } from "react-redux";

export default function TransactionsPage() {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.admin);
    const totalAmount = useTotalPrice(
        data?.totalTransactionDetails,
        "totalAmount"
    );
    const totalFee = useTotalPrice(data?.totalTransactionDetails, "totalFee");

    const fetchTransactionsDetails = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get("/admin/transactions/details", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            setData(response.data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchTransactionsDetails();
    }, []);

    return (
        <div>
            <div className="mb-5 flex items-center justify-between">
                <h1 className="font-[600] text-lg">Transactions</h1>
                <Link
                    to="/transactions-list"
                    className="text-primaryColor text-sm font-medium underline cursor-pointer"
                >
                    Transactions List
                </Link>
            </div>

            {isLoading ? (
                <div className="flex justify-center">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-4 gap-6">
                        <InfoCard
                            title="Total Transactions"
                            value={data?.totalTransactions}
                        />
                        <InfoCard
                            title="Completed Transactions"
                            value={data?.completedTransactions}
                        />
                        <InfoCard
                            title="Total Transactions Amount"
                            value={`$ ${formatBalance(totalAmount)}`}
                        />
                        <InfoCard
                            title="Total Transaction Revenue"
                            value={`$ ${formatBalance(totalFee)}`}
                        />
                    </div>

                    <div className="grid grid-cols-[65%_auto] gap-6 mt-6">
                        <TransactionsVolumeGraph />
                        <TransactionsCurrencyGraph
                            txnData={data?.totalTransactionDetails}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
