import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import axios from "../axios";
import { StatesTable } from "../features/SingleCountryStatesPage/";
import { Loader } from "../components";
import AddNewStateModal from "../features/SingleCountryStatesPage/components/AddNewStateModal";

export default function SingleCountryStatesPage() {
    const [isStateAddModalOpen, setIsStateAddModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [states, setStates] = useState([]);

    const { isocode } = useParams();
    const { jwtToken } = useSelector((state) => state.admin);

    const fetchCountryStates = useCallback(async () => {
        try {
            setIsLoading(true);

            const respose = await axios.get(
                `/admin/states/country/${isocode}`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            setStates(respose.data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchCountryStates();
    }, [fetchCountryStates]);

    return (
        <div>
            <div className="flex items-center gap-[10px] justify-between mb-5">
                <h1 className="font-[600] text-lg">States ({isocode})</h1>
                <button onClick={() => setIsStateAddModalOpen(true)}>
                    + Add State
                </button>
            </div>

            {isLoading ? (
                <div className="flex justify-center">
                    <Loader />
                </div>
            ) : (
                <StatesTable states={states} />
            )}

            <AddNewStateModal
                isStateAddModalOpen={isStateAddModalOpen}
                setIsStateAddModalOpen={setIsStateAddModalOpen}
                setStates={setStates}
            />
        </div>
    );
}
