import React from "react";
import KycSinglePage from "../features/KycApprovalPage/components/KycSinglePage";

function KycSingleDetailPage() {
  return (
    <div>
      <KycSinglePage />
    </div>
  );
}

export default KycSingleDetailPage;
