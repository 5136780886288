import React from "react";

export default function ReportsTable() {
    return (
        <div className="overflow-x-auto border border-[#333] rounded">
            <table className="w-full bg-darkCardBgColor text-darkGrayColor text-[14px]">
                <thead className="text-left">
                    <tr>
                        <th className="font-[600] px-4 py-3">Id.</th>
                        <th className="font-[600] px-4 py-3">Name</th>
                        <th className="font-[600] px-4 py-3">Type</th>
                        <th className="font-[600] px-4 py-3">Country</th>
                        <th className="font-[600] px-4 py-3">Balance</th>
                        <th className="font-[600] px-4 py-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: 10 })?.map((_, index) => {
                        return (
                            <tr
                                key={index}
                                className="border-t border-[#24262d]"
                            >
                                <td className="px-4 py-3">10</td>
                                <td className="px-4 py-3">
                                    <div className="flex items-center gap-[12px]">
                                        <div className="w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-full overflow-hidden">
                                            <img
                                                src=""
                                                alt=""
                                                className="w-[100%] h-[100%] object-cover"
                                            />
                                        </div>
                                        <div>
                                            <span className="block">
                                                Nihal N
                                            </span>
                                            <span className="block">
                                                @nihaln
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-4 py-3">Merchant</td>
                                <td className="px-4 py-3">India</td>
                                <td className="px-4 py-3">$300</td>
                                <td className="px-4 py-3"></td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
