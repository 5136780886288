import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "../axios";
import { InfoCard, Loader } from "../components";
import {
    SingleCurrenyTransactionsVolumeGraph,
    TopHoldersList,
} from "../features/SingleCurrencyPage";
import { formatBalance, formatPrice, getCurrencyTotalPrice } from "../utils";

export default function SingleCurrencyPage() {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { id } = useParams();
    const { prices } = useSelector((state) => state.currencies);
    const { jwtToken } = useSelector((state) => state.admin);

    const fetchCurrencyDetails = async () => {
        try {
            setIsLoading(true);

            const response = await axios.get(`/admin/currencies/single/${id}`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setData(response.data);
            setIsLoading(false)
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchCurrencyDetails();
    }, []);

    if (isLoading) {
        return <div className="flex justify-center"><Loader /></div>
    }

    return (
        <div>
            <div className="flex items-start gap-[20px] justify-between mb-6">
                <div className="flex items-center gap-[15px]">
                    <div className="w-[50px] min-w-[50px] h-[50px] min-h-[50px] rounded-full overflow-hidden bg-[#fff]">
                        <img
                            src={
                                process.env?.REACT_APP_SERVER_URL +
                                data?.currency?.logo
                            }
                            alt=""
                            className="w-[100%] h-[100%] object-cover"
                        />
                    </div>
                    <div>
                        <div className="flex items-center gap-[10px]">
                            <h2 className="font-medium">
                                {data?.currency?.name}
                            </h2>
                            <span className="text-darkGrayColor font-medium text-sm">
                                {data?.currency?.symbol}
                            </span>
                        </div>
                        <div className="mt-2 flex flex-wrap items-center gap-[12px]">
                            {data?.currency?.assets?.map((asset, index) => {
                                return (
                                    <span
                                        key={index}
                                        className="text-sm bg-darkCardBgColor py-1 px-2 rounded"
                                    >
                                        {asset?.network?.tokenStandard}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-[15px]">
                    <span className="block font-medium">
                        {formatBalance(data?.totalBalance)}{" "}
                        {data?.currency?.symbol}
                    </span>
                    <span className="block font-medium">
                        ${" "}
                        {formatPrice(
                            getCurrencyTotalPrice(
                                prices,
                                data?.currency,
                                data?.totalBalance
                            )
                        )}
                    </span>
                </div>
            </div>

            <div className="grid grid-cols-4 gap-6 mb-6">
                <InfoCard
                    title="Total Transactions"
                    value={data?.totalTransactions}
                />
                <InfoCard
                    title="Completed Transactions"
                    value={data?.completedTransactions}
                />
                <InfoCard
                    title="Total Transaction Volume"
                    value={`${formatBalance(data?.totalAmount)} ${
                        data?.currency?.symbol
                    }`}
                />
                <InfoCard
                    title="Total Fee"
                    value={`${formatBalance(data?.totalFee)} ${
                        data?.currency?.symbol
                    }`}
                />
            </div>

            <div className="grid grid-cols-[65%_auto] gap-6 mt-6">
                <SingleCurrenyTransactionsVolumeGraph />
                <TopHoldersList
                    data={data?.topAccountsByBalance}
                    currency={data?.currency}
                />
            </div>
        </div>
    );
}
