import React, { useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";

import { useHandleClickOutside } from "../../../hooks";
import axios from "../../../axios";

export default function AddNetworkModal({
    isAddNetworkModalOpen,
    setIsAddNetworkModalOpen,
    setNetworks,
}) {
    const [data, setData] = useState({
        name: "",
        tokenStandard: "",
        networkVM: "",
        rpcUrl: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const { jwtToken } = useSelector((state) => state.admin);
    const wrapperRef = useRef();
    useHandleClickOutside(wrapperRef, () => setIsAddNetworkModalOpen(false));

    const handleChange = (e) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            const response = await axios.post("/admin/networks/add", data, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setNetworks((prev) => {
                return [...prev, response.data];
            });
            setData({ name: "", tokenStandard: "", networkVM: "", rpcUrl: "" });
            setIsLoading(false);
            setIsAddNetworkModalOpen(false);
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div
            className={
                "fixed top-0 left-0 bg-[#000] w-full h-full z-10 transition-all " +
                (isAddNetworkModalOpen
                    ? "visible bg-[#0004]"
                    : "hidden bg-[#0000]")
            }
        >
            <form
                className={
                    "fixed top-0 right-0 flex flex-col bg-darkCardBgColor w-[580px] max-w-[100%] h-full z-10 flex-colum transition-all " +
                    (isAddNetworkModalOpen
                        ? "translate-x-0"
                        : "translate-x-[100%]")
                }
                onSubmit={handleSubmit}
                ref={wrapperRef}
            >
                <div className="flex items-center justify-between p-5">
                    <div className="text-[#d5d6d7]">
                        <h4 className="font-[600] text-lg">Add Network</h4>
                        <p className="text-sm text-darkGrayColor mt-[2px]">
                            Add network and necessary information here
                        </p>
                    </div>
                    <button
                        type="button"
                        className="bg-[#fff] text-[#f05252] w-[25px] h-[25px] rounded-full text-base flex items-center justify-center p-0 hover:bg-[#ffe1e1]"
                        onClick={() => {
                            setIsAddNetworkModalOpen(false);
                        }}
                    >
                        <IoClose />
                    </button>
                </div>
                <div className="flex-1 overflow-y-auto p-5 bg-[#24262d]">
                    <div className="">
                        <label htmlFor="">Network Name</label>
                        <input
                            type="text"
                            placeholder="Ex: Ethereum"
                            name="name"
                            value={data.name || ""}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">Token Standard</label>
                        <input
                            type="text"
                            placeholder="Ex: ERC20"
                            name="tokenStandard"
                            onChange={handleChange}
                            value={data.tokenStandard || ""}
                            required
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">Network VM</label>
                        <select
                            id=""
                            name="networkVM"
                            value={data.networkVM || ""}
                            onChange={handleChange}
                            required
                        >
                            <option value="" hidden>
                                Select Network VM
                            </option>
                            <option value="ethereum">Ethereum</option>
                            <option value="solana">Solana</option>
                            <option value="bitcoin">Bitcoin</option>
                            <option value="bitcoin-testnet">
                                Bitcoin Testnet
                            </option>
                        </select>
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">RPC Url</label>
                        <input
                            type="text"
                            placeholder="Ex: ERC20"
                            name="rpcUrl"
                            onChange={handleChange}
                            value={data.rpcUrl || ""}
                            required={
                                data.networkVM === "ethereum" ||
                                data.networkVM === "solana"
                            }
                        />
                    </div>

                    {error && (
                        <span className="block text-sm text-red-500 mt-4">
                            {error}
                        </span>
                    )}
                </div>

                <div className="flex items-center gap-[1em] p-5">
                    <button
                        type="button"
                        className="w-full text-[#707275] bg-[#24262d] transition-all hover:bg-transparent border border-transparent hover:border-[#24262d] hover:text-red-500"
                        onClick={() => {
                            setIsAddNetworkModalOpen(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button className="w-full" disabled={isLoading}>
                        Add Network
                    </button>
                </div>
            </form>
        </div>
    );
}
