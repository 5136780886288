import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    countries: [],
    totalCountries: 0,
    activeCountries: 0,
    totalStates: 0,
};

export const countriesSlice = createSlice({
    name: "countries",
    initialState,
    reducers: {
        setCountries: (state, action) => {
            state.countries = action.payload?.countries;
            state.totalCountries = action.payload?.totalCountries;
            state.activeCountries = action.payload?.activeCountries;
            state.totalStates = action.payload?.totalStates;
        },
        addNewCountry: (state, action) => {
            state.countries?.unshift(action.payload);
            state.totalCountries += 1;
            state.activeCountries += 1;
        },
    },
});

export const { setCountries, addNewCountry } = countriesSlice.actions;

export default countriesSlice.reducer;
