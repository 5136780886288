import React, { useEffect, useState } from "react";
import axios from "../axios";
import { useCallback } from "react";
import { NetworksTable, AddNetworkModal } from "../features/NetworksPage/";
import { useSelector } from "react-redux";
import { Loader } from "../components";

export default function NetworksPage() {
    const [isAddNetworkModalOpen, setIsAddNetworkModalOpen] = useState(false);
    const [networks, setNetworks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.admin);

    const fetchNetworks = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get("/admin/networks/all", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setNetworks(data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchNetworks();
    }, [fetchNetworks]);

    return (
        <div>
            <div className="flex items-center gap-[10px] justify-between mb-5">
                <h1 className="font-[600] text-lg">Networks</h1>
                <button onClick={() => setIsAddNetworkModalOpen(true)}>
                    + Add Network
                </button>
            </div>

            {isLoading ? (
                <div className="flex justify-center">
                    <Loader />
                </div>
            ) : (
                <>
                    <div>
                        <NetworksTable networks={networks} />
                    </div>
                </>
            )}
            
            <AddNetworkModal
                isAddNetworkModalOpen={isAddNetworkModalOpen}
                setIsAddNetworkModalOpen={setIsAddNetworkModalOpen}
                setNetworks={setNetworks}
            />
        </div>
    );
}
