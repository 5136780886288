import React from "react";

export default function InfoCard({ title, value }) {
    return (
        <div className="bg-darkCardBgColor p-4 rounded">
            <span className="block text-darkGrayColor text-sm">{title}</span>
            <span className="block font-[600] text-lg mt-1">{value}</span>
        </div>
    );
}
