import React, { useCallback, useEffect, useState } from "react";

import axios from "../axios";
import { AddCurrencyModal, CurrenciesTable } from "../features/CurrenciesPage";
import { InfoCard, Loader } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { setCurrencyDetails } from "../redux/slices/currenciesSlice";

export default function CurrenciesPage() {
    const [isAddCurrencyModalOpen, setIsAddCurrencyModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.admin);
    const { totalCurrencies, activeCurrencies, totalAssets, totalTokens } =
        useSelector((state) => state.currencies);
    const dispatch = useDispatch();

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await axios.get("/admin/currencies/all", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            dispatch(setCurrencyDetails(response.data));
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [dispatch, jwtToken]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <div className="flex items-center gap-[10px] justify-between mb-5">
                <h1 className="font-[600] text-lg">Currencies</h1>
                <button onClick={() => setIsAddCurrencyModalOpen(true)}>
                    + Add Currency
                </button>
            </div>

            {isLoading ? (
                <div className="flex justify-center">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-4 gap-6 mb-6">
                        <InfoCard
                            title="Total Currencies"
                            value={totalCurrencies}
                        />
                        <InfoCard
                            title="Active Currencies"
                            value={activeCurrencies}
                        />
                        <InfoCard title="Total Assets" value={totalAssets} />
                        <InfoCard title="Total Tokens" value={totalTokens} />
                    </div>

                    <div>
                        <CurrenciesTable />
                    </div>
                </>
            )}
            <AddCurrencyModal
                isAddCurrencyModalOpen={isAddCurrencyModalOpen}
                setIsAddCurrencyModalOpen={setIsAddCurrencyModalOpen}
            />
        </div>
    );
}
