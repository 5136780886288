import React, { useEffect, useCallback } from "react";
import { useState } from "react";

import axios from "../axios";
import { UsersTable } from "../features/UsersListPage";
import { useSelector } from "react-redux";
import { Loader } from "../components";

export default function UsersListPage() {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.admin);

    const fetchUsers = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get("/admin/users/all", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setUsers(data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return (
        <div>
            <div className="mb-5">
                <h1 className="font-[600] text-lg">Users List</h1>
            </div>

            <div className="flex items-center gap-[10px] bg-darkCardBgColor p-5 rounded mt-6">
                <input
                    type="text"
                    className="h-[45px]"
                    placeholder="Search User..."
                />
                <button className="w-[200px] h-[45px]">Search</button>
            </div>

            {isLoading ? (
                <div className="flex justify-center">
                    <Loader />
                </div>
            ) : (
                <div className="mt-6">
                    <UsersTable users={users} />
                </div>
            )}
        </div>
    );
}
