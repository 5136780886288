import React from "react";
import { FiEdit } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    formatBalance,
    formatPrice,
    getCurrencyTotalPrice,
} from "../../../utils";

export default function CurrenciesTable() {
    const { prices, currencies } = useSelector((state) => state.currencies);
    const navigate = useNavigate();

    return (
        <div className="overflow-x-auto border border-[#333] rounded">
            <table className="w-full bg-darkCardBgColor text-darkGrayColor text-[14px]">
                <thead className="text-left">
                    <tr>
                        <th className="font-[600] px-4 py-3">Curr No.</th>
                        <th className="font-[600] px-4 py-3">Name</th>
                        <th className="font-[600] px-4 py-3">Total Balance</th>
                        <th className="font-[600] px-4 py-3">Price</th>
                        <th className="font-[600] px-4 py-3">Assets</th>
                        <th className="font-[600] px-4 py-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currencies?.map((currency, index) => {
                        return (
                            <tr
                                key={index}
                                className="border-t border-[#24262d] cursor-pointer hover:bg-[#22252b]"
                                onClick={() => navigate(`${currency?._id}`)}
                            >
                                <td className="px-4 py-3">
                                    #{currency?.currencyNumber}
                                </td>
                                <td className="px-4 py-3">
                                    <div className="flex items-center gap-[12px]">
                                        <div className="w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-full overflow-hidden">
                                            <img
                                                src={
                                                    process.env
                                                        .REACT_APP_SERVER_URL +
                                                    currency?.logo
                                                }
                                                alt=""
                                                className="w-[100%] h-[100%] object-cover"
                                            />
                                        </div>
                                        <div>
                                            <span className="block">
                                                {currency?.name}
                                            </span>
                                            <span className="block">
                                                {currency?.symbol}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-4 py-3">
                                    {formatBalance(currency?.balance)}{" "}
                                    {currency?.symbol}
                                </td>
                                <td className="px-4 py-3">
                                    ${" "}
                                    {formatPrice(
                                        getCurrencyTotalPrice(
                                            prices,
                                            currency,
                                            currency?.balance
                                        )
                                    )}
                                </td>
                                <td className="px-4 py-3">
                                    <div className="flex flex-wrap items-center gap-[10px]">
                                        {currency?.assets?.map(
                                            (asset, index) => {
                                                return (
                                                    <span
                                                        key={index}
                                                        className="block bg-[#2a2d31] rounded py-[1px] px-2"
                                                    >
                                                        {
                                                            asset?.network
                                                                ?.tokenStandard
                                                        }
                                                    </span>
                                                );
                                            }
                                        )}
                                    </div>
                                </td>
                                <td className="px-4 py-3">
                                    <span className="cursor-pointer hover:text-green-500">
                                        <FiEdit />
                                    </span>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
