import React from "react";

function PresaleListTable({ presales }) {
  return (
    <div className="overflow-x-auto border border-[#333] rounded">
      <table className="w-full bg-darkCardBgColor text-darkGrayColor text-[14px]">
        <thead className="text-left">
          <tr>
            <th className="font-[600] px-4 py-3">ID</th>
            <th className="font-[600] px-4 py-3">Token Name</th>
            <th className="font-[600] px-4 py-3">Token Symbol</th>
            <th className="font-[600] px-4 py-3">Total supply</th>
          </tr>
        </thead>
        <tbody>
          {presales?.map((presale, index) => {
            return (
              <tr key={index} className="border-t border-[#24262d]">
                <td className="px-4 py-3">{presale?.id}</td>
                <td className="px-4 py-3">{presale?.token_name}</td>
                <td className="px-4 py-3">{presale?.token_symbol}</td>
                <td className="px-4 py-3">{presale?.total_supply || "NA"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default PresaleListTable;
