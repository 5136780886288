import { MainLayout } from "../layouts";
import AdminsListPage from "../pages/AdminsListPage";
import AdmInternalTransactionPage from "../pages/AdmInternalTransactionPage";
import CountriesPage from "../pages/CountriesPage";
import CurrenciesPage from "../pages/CurrenciesPage";
import Dashboard from "../pages/Dashboard";
import KycApprovalPage from "../pages/KycApprovalPage";
import KycSingleDetailPage from "../pages/KycSingleDetailPage";
import LoginPage from "../pages/LoginPage";
import NetworksPage from "../pages/NetworksPage";
import PaymentsPage from "../pages/PaymentsPage";
import PresalePageList from "../pages/PresalePageList";
import ReportsPage from "../pages/ReportsPage";
import SettingsPage from "../pages/SettingsPage";
import SingleCountryStatesPage from "../pages/SingleCountryStatesPage";
import SingleCurrencyPage from "../pages/SingleCurrencyPage";
import SingleUserPage from "../pages/SingleUserPage";
import TransactionsListPage from "../pages/TransactionsListPage";
import TransactionsPage from "../pages/TransactionsPage";
import UsersListPage from "../pages/UsersListPage";
import UsersPage from "../pages/UsersPage";
import AdminPrivateRoute from "./AdminPrivateRoute";
import AuthPrivateRoute from "./AuthPrivateRoute";
import SuperAdminPrivateRoute from "./SuperAdminPrivateRoute";

const ThemeRoutes = [
  {
    path: "",
    element: (
      <AdminPrivateRoute>
        <MainLayout />
      </AdminPrivateRoute>
    ),
    children: [
      { path: "", element: <Dashboard /> },
      { path: "/transactions", element: <TransactionsPage /> },
      { path: "/transactions-list", element: <TransactionsListPage /> },
      { path: "/currencies", element: <CurrenciesPage /> },
      { path: "/payments", element: <PaymentsPage /> },
      { path: "/users", element: <UsersPage /> },
      { path: "/kyc-approval", element: <KycApprovalPage /> },
      { path: "/kyc-single/:id", element: <KycSingleDetailPage /> },
      { path: "/reports", element: <ReportsPage /> },
      { path: "/settings", element: <SettingsPage /> },
      { path: "/currencies/:id", element: <SingleCurrencyPage /> },
      { path: "/users/:id", element: <SingleUserPage /> },
      { path: "/users-list", element: <UsersListPage /> },
      { path: "/countries", element: <CountriesPage /> },
      { path: "/networks", element: <NetworksPage /> },

      {
        path: "/presale",
        element: <PresalePageList />,
      },
      {
        path: "/internal-transactions",
        element: <AdmInternalTransactionPage />,
      },
    ],
  },
  {
    path: "",
    element: (
      <SuperAdminPrivateRoute>
        <MainLayout />
      </SuperAdminPrivateRoute>
    ),
    children: [{ path: "/admins", element: <AdminsListPage /> }],
  },
  {
    path: "/login",
    element: (
      <AuthPrivateRoute>
        <LoginPage />
      </AuthPrivateRoute>
    ),
  },
];

export default ThemeRoutes;
