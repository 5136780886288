import React from "react";

export default function UsersTopCitiesList() {
    return (
        <div className="bg-darkCardBgColor rounded p-5 h-full">
            <h2 className="font-medium text-base mb-6">City</h2>
            {Array.from({ length: 5 })?.map((_, index) => {
                return (
                    <div
                        key={index}
                        className="flex flex-wrap items-center justify-between mb-[10px] last:mb-0 gap-[10px]"
                    >
                        <span className="block text-darkTextColor font-medium">
                            Dubai
                        </span>
                        <div className="text-right">
                            <span className="block font-medium text-[14px] text-[#ccc]">
                                50%
                            </span>
                            <span className="block text-sm mt-[2px] text-darkGrayColor">
                                400
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
