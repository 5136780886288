import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { formatDate } from "../../../utils";
import { MdDelete } from "react-icons/md";
import axios from "../../../axios";
import { deleteAdmin } from "../../../redux/slices/adminSlice";

export default function AdminsTable() {
    const { admins } = useSelector((state) => state.admin);
    const dispatch = useDispatch();

    const { jwtToken } = useSelector((state) => state.admin);

    const handleDelete = async (id) => {
        try {
            const isConfirm = window.confirm("Are you sure?");
            if (isConfirm) {
                await axios.delete(`/admin/auth/delete/${id}`, {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                });
                dispatch(deleteAdmin(id));
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="overflow-x-auto border border-[#333] rounded">
            <table className="w-full bg-darkCardBgColor text-darkGrayColor text-[14px]">
                <thead className="text-left">
                    <tr>
                        <th className="font-[600] px-4 py-3">Id.</th>
                        <th className="font-[600] px-4 py-3">Email</th>
                        <th className="font-[600] px-4 py-3">Role</th>
                        <th className="font-[600] px-4 py-3">Last LoggedIn</th>
                        <th className="font-[600] px-4 py-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {admins?.map((admin, index) => {
                        return (
                            <tr
                                className="border-t border-[#24262d]"
                                key={index}
                            >
                                <td className="px-4 py-3">
                                    #{admin?._id?.slice(0, 6)}
                                </td>
                                <td className="px-4 py-3">{admin?.email}</td>
                                <td className="px-4 py-3">{admin?.role}</td>
                                <td className="px-4 py-3">
                                    {admin?.lastLogin
                                        ? formatDate(admin?.lastLogin)
                                        : "Not logged in yet"}
                                </td>
                                <td className="px-4 py-3">
                                    <button
                                        className="p-0 h-auto text-xl bg-transparent hover:bg-transparent hover:text-red-500"
                                        onClick={() => handleDelete(admin?._id)}
                                    >
                                        <MdDelete />
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
