import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../axios";
import { useSelector } from "react-redux";
import { Loader } from "../../../components";

function KycSinglePage({ setIsModal, id, fetchKycDetails }) {
  console.log(id, "shw id");

  const [kycData, setKycData] = useState(null);
  const { jwtToken } = useSelector((state) => state.admin);

  const fetchSingleDetails = async () => {
    try {
      const res = await axios.get(`/admin/kyc/single/${id}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setKycData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSingleDetails();
  }, [id]);

  const handleApproval = async (status) => {
    try {
      const res = await axios.patch(
        `/admin/kyc/verify/${id}`,
        {
          userId: kycData?.user?._id,
          kycStatus: status,
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      fetchKycDetails();

      setIsModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-darkCardBgColor text-darkGrayColor rounded-xl shadow-md overflow-hidden p-6 w-full max-w-2xl min-h-[400px]">
        <h1
          className=" flex justify-end text-gray-500 cursor-pointer"
          onClick={() => {
            setIsModal(false);
          }}
        >
          X
        </h1>
        {kycData ? (
          <div className="flex flex-col md:flex-row">
            <div className="md:shrink-0 md:mr-6">
              <img
                className="h-48 w-48 md:h-64 md:w-64 object-cover rounded"
                src={process.env.REACT_APP_SERVER_URL + kycData?.idProofImg}
                alt="ID Proof"
              />
            </div>
            <div className="mt-4 md:mt-0">
              <div className="text-xl font-bold text-white">
                {kycData?.firstName} {kycData?.lastName}
              </div>
              <p className="text-sm text-gray-300 mt-1">
                {kycData?.user?.type} - {kycData?.user?.country?.countryName}
              </p>
              <div className="mt-4 grid grid-cols-1 gap-2">
                <p className="text-sm text-gray-400">Phone: {kycData?.phone}</p>
                <p className="text-sm text-gray-400">
                  Email: {kycData?.user?.email}
                </p>
                <p className="text-sm text-gray-400">
                  Company UIN: {kycData?.company_UIN}
                </p>
                <p className="text-sm text-gray-400">
                  CST No: {kycData?.CSTNo}
                </p>
                <p className="text-sm text-gray-400">
                  VAT/TIN: {kycData?.VatOrTin}
                </p>
                <p className="text-sm text-gray-400">
                  PAN: {kycData?.pan_number}
                </p>
                <p className="text-sm text-gray-400">
                  Service Tax No: {kycData?.service_tax_no}
                </p>
                <p className="text-sm text-gray-400">
                  Address: {kycData?.address}, {kycData?.city},{" "}
                  {kycData?.pincode}
                </p>
                <p className="text-sm text-gray-400">
                  Country: {kycData?.country}
                </p>
                <p className="text-sm text-gray-400">
                  Declaration: {kycData?.declaration}
                </p>
              </div>
              <div className="mt-6 flex space-x-4">
                <button
                  className="bg-green-600 hover:bg-green-800 text-white px-4 py-2 rounded"
                  onClick={() => handleApproval("verified")}
                >
                  Approve
                </button>
                <button
                  className="bg-red-600 hover:bg-red-800 text-white px-4 py-2 rounded"
                  onClick={() => handleApproval("rejected")}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col md:flex-row">
            <div className="md:shrink-0 md:mr-6">
              <div className="w-72 h-full animate-pulse bg-gray-700"></div>
            </div>
            <div className="mt-4 md:mt-0">
              <div className="text-xl font-bold text-white animate-pulse w-56 h-10 bg-gray-700 mb-4"></div>
              <p className="text-sm text-gray-400 bg-gray-700 animate-pulse w-64 h-3"></p>
              <div className="mt-4 grid grid-cols-1 gap-2">
                <p className="text-sm text-gray-400 bg-gray-700 animate-pulse w-52 h-3"></p>
                <p className="text-sm text-gray-400 bg-gray-700 animate-pulse w-52 h-3"></p>
                <p className="text-sm text-gray-400 bg-gray-700 animate-pulse w-16 h-3"></p>
                <p className="text-sm text-gray-400 bg-gray-700 animate-pulse w-52 h-3"></p>
                <p className="text-sm text-gray-400 bg-gray-700 animate-pulse w-52 h-3"></p>
                <p className="text-sm text-gray-400 bg-gray-700 animate-pulse w-40 h-3"></p>
                <p className="text-sm text-gray-400 bg-gray-700 animate-pulse w-52 h-3"></p>
                <p className="text-sm text-gray-400 bg-gray-700 animate-pulse w-52 h-3"></p>
                <p className="text-sm text-gray-400 bg-gray-700 animate-pulse w-32 h-3"></p>
                <p className="text-sm text-gray-400 bg-gray-700 animate-pulse w-64 h-3"></p>
              </div>
              <div className="mt-6 flex space-x-4">
                <button className="bg-gray-700 animate-pulse text-white px-4 py-2 rounded w-20 "></button>
                <button className="bg-gray-700 animate-pulse text-white px-4 py-2 rounded w-20 "></button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default KycSinglePage;
