import React from "react";
import SingleUsersTableRow from "./SingleUsersTableRow";

export default function UsersTable({ users }) {
    return (
        <div className="overflow-x-auto border border-[#333] rounded">
            <table className="w-full bg-darkCardBgColor text-darkGrayColor text-[14px]">
                <thead className="text-left">
                    <tr>
                        <th className="font-[600] px-4 py-3">Id.</th>
                        <th className="font-[600] px-4 py-3">Name</th>
                        <th className="font-[600] px-4 py-3">Type</th>
                        <th className="font-[600] px-4 py-3">Country</th>
                        <th className="font-[600] px-4 py-3">Balance</th>
                        <th className="font-[600] px-4 py-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map((user, index) => {
                        return <SingleUsersTableRow key={index} user={user} />;
                    })}
                </tbody>
            </table>
        </div>
    );
}
