import React from "react";
import { useSelector } from "react-redux";

import {
    formatBalance,
    formatPrice,
    getCurrencyTotalPrice,
} from "../../../utils";

export default function BalancesListCard({ balances }) {
    const { prices } = useSelector((state) => state.currencies);

    return (
        <div className="bg-darkCardBgColor rounded p-5 h-full">
            <h2 className="font-medium text-base mb-6">
                Users Total Balance List
            </h2>
            {balances?.slice(0, 5)?.map((item, index) => {
                return (
                    <div
                        key={index}
                        className="flex flex-wrap items-center justify-between mb-5 last:mb-0 gap-[10px]"
                    >
                        <div className="flex flex-wrap items-center gap-[12px]">
                            <div className="w-[35px] h-[35px] rounded-full overflow-hidden">
                                <img
                                    src={
                                        process.env.REACT_APP_SERVER_URL +
                                        item?.currency?.logo
                                    }
                                    alt=""
                                />
                            </div>
                            <div>
                                <span className="block text-darkTextColor font-medium">
                                    {item?.currency?.symbol}
                                </span>
                                <span className="block text-sm text-darkGrayColor">
                                    {item?.currency?.name}
                                </span>
                            </div>
                        </div>
                        <div className="text-right">
                            <span className="block font-medium text-[14px] text-[#ccc]">
                                {formatBalance(item?.balance)}{" "}
                                {item?.currency?.symbol}
                            </span>
                            <span className="block text-sm text-darkGrayColor">
                                $
                                {formatPrice(
                                    getCurrencyTotalPrice(
                                        prices,
                                        item?.currency,
                                        item?.balance
                                    )
                                )}
                            </span>
                        </div>
                    </div>
                );
            })}

            <div className="text-center mt-8">
                <span className="text-sm font-medium text-primaryColor cursor-pointer">
                    View More
                </span>
            </div>
        </div>
    );
}
