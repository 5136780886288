import BigNumber from "bignumber.js";

const getCurrencyTotalPrice = (prices, currency, value) => {
    let price = 0;
    if (!currency || prices?.length < 1 || !value) {
        return "0.0";
    }

    for (let i = 0; i < prices?.length; i++) {
        if (currency.symbol === prices[i]?.symbol) {
            price = prices[i]?.price_usd;
            break;
        }
    }

    if (!price || !value) {
        return "0.0";
    }

    return BigNumber(price).multipliedBy(value).toString();
};

export default getCurrencyTotalPrice;
