import React, { useEffect, useRef, useState, useCallback } from "react";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import { useHandleClickOutside, useImageChange } from "../../../hooks";
import axios from "../../../axios";
import { addNewCurrency } from "../../../redux/slices/currenciesSlice";

export default function AddCurrencyModal({
  isAddCurrencyModalOpen,
  setIsAddCurrencyModalOpen,
}) {
  const [data, setData] = useState({
    networkId: "",
    currencyId: "",
    isToken: false,
    isCombined: false,
    tokenAddress: "",
    name: "",
    symbol: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [networks, setNetworks] = useState([]);

  const { jwtToken } = useSelector((state) => state.admin);
  const { currencies } = useSelector((state) => state.currencies);
  const wrapperRef = useRef();
  const dispatch = useDispatch();
  useHandleClickOutside(wrapperRef, () => setIsAddCurrencyModalOpen(false));
  const {
    image: logo,
    error: logoError,
    handleImageChange: handleLogoChange,
  } = useImageChange();

  const handleChange = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setError("");
      setIsLoading(true);

      const formData = new FormData();
      formData.append("logo", logo);
      formData.append("isCombined", data.isCombined);
      formData.append("currencyId", data.currencyId);
      formData.append("isToken", data.isToken);
      formData.append("name", data.name);
      formData.append("networkId", data.networkId);
      formData.append("symbol", data.symbol);
      formData.append("tokenAddress", data.tokenAddress);

      const response = await axios.post(`/admin/currencies/add`, formData, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });

      dispatch(addNewCurrency(response.data));
      setData({
        networkId: "",
        currencyId: "",
        isToken: false,
        isCombined: false,
        tokenAddress: "",
        name: "",
        symbol: "",
      });
      setIsLoading(false);
      setIsAddCurrencyModalOpen(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setError(err?.response?.data?.error || "Something went wrong, Try again");
    }
  };

  const fetchNetworks = useCallback(async () => {
    try {
      const response = await axios.get("/admin/networks/all", {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setNetworks(response.data);
    } catch (err) {
      console.log(err);
    }
  }, [jwtToken]);

  useEffect(() => {
    fetchNetworks();
  }, [fetchNetworks]);

  return (
    <div
      className={
        "fixed top-0 left-0 bg-[#000] w-full h-full z-10 transition-all " +
        (isAddCurrencyModalOpen ? "visible bg-[#0004]" : "hidden bg-[#0000]")
      }
    >
      <form
        className={
          "fixed top-0 right-0 flex flex-col bg-darkCardBgColor w-[580px] max-w-[100%] h-full z-10 flex-colum transition-all " +
          (isAddCurrencyModalOpen ? "translate-x-0" : "translate-x-[100%]")
        }
        onSubmit={handleSubmit}
        ref={wrapperRef}
      >
        <div className="flex items-center justify-between p-5">
          <div className="text-[#d5d6d7]">
            <h4 className="font-[600] text-lg">Add Currency</h4>
            <p className="text-sm text-darkGrayColor mt-[2px]">
              Add currency and necessary information here
            </p>
          </div>
          <button
            type="button"
            className="bg-[#fff] text-[#f05252] w-[25px] h-[25px] rounded-full text-base flex items-center justify-center p-0 hover:bg-[#ffe1e1]"
            onClick={() => {
              setIsAddCurrencyModalOpen(false);
            }}
          >
            <IoClose />
          </button>
        </div>
        <div className="flex-1 overflow-y-auto p-5 bg-[#24262d]">
          <div>
            <label htmlFor="">Network</label>
            <select
              name="networkId"
              value={data.networkId}
              onChange={handleChange}
              id=""
              required
            >
              <option value="" hidden>
                Select Network
              </option>
              {networks?.map((network, index) => {
                return (
                  <option value={network?._id} key={index}>
                    {network?.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mt-4">
            <label htmlFor="">is Token</label>
            <div className="flex items-center gap-[15px]">
              <div className="flex items-center gap-[10px]">
                <input
                  type="radio"
                  name="isToken"
                  id=""
                  value="no"
                  checked={data.isToken === false}
                  className="w-[16px] h-[16px]"
                  onChange={(e) =>
                    setData((prev) => {
                      return {
                        ...prev,
                        isToken: e.target.value === "yes",
                      };
                    })
                  }
                />
                <label htmlFor="" className="mb-0">
                  No
                </label>
              </div>
              <div className="flex items-center gap-[10px]">
                <input
                  type="radio"
                  name="isToken"
                  id=""
                  value="yes"
                  className="w-[16px] h-[16px]"
                  checked={data.isToken === true}
                  onChange={(e) =>
                    setData((prev) => {
                      return {
                        ...prev,
                        isToken: e.target.value === "yes",
                      };
                    })
                  }
                />
                <label htmlFor="" className="mb-0">
                  Yes
                </label>
              </div>
            </div>
          </div>
          {data.isToken && (
            <div className="mt-4">
              <label htmlFor="">Token Address</label>
              <input
                type="text"
                placeholder="Enter token address"
                name="tokenAddress"
                value={data.tokenAddress || ""}
                onChange={handleChange}
                required
              />
            </div>
          )}
          <div className="mt-4 flex items-start gap-[15px]">
            <input
              type="checkbox"
              className="w-[16px] h-[16px] min-w-[16px] min-h-[16px] mt-1"
              onChange={(e) => {
                setData((prev) => {
                  return {
                    ...prev,
                    isCombined: e.target.checked,
                  };
                });
              }}
              checked={data.isCombined === true}
            />
            <label htmlFor="" className="font-normal mb-0">
              Do you want to add this with another token or coin that already
              exists in SimplePay?{" "}
              <span className="text-primaryColor">
                (ex: USDT(ERC20) - USDT(BEP20))
              </span>
            </label>
          </div>
          {data.isCombined && (
            <div className="mt-4">
              <label htmlFor="">Currency</label>
              <select
                name="currencyId"
                value={data.currencyId || ""}
                onChange={handleChange}
                id=""
                required
              >
                <option value="" hidden>
                  Select Currency
                </option>
                {currencies?.map((currency, index) => {
                  return (
                    <option value={currency?._id} key={index}>
                      {currency?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {!data.isCombined && (
            <div className="mt-4">
              <label htmlFor="">Logo</label>
              <input
                type="file"
                className="h-auto py-[6px]"
                onChange={handleLogoChange}
                required
              />
              {logo && (
                <div className="w-[40px] h-[40px] mt-3">
                  <img
                    src={URL.createObjectURL(logo)}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
              {logoError && (
                <span className="text-sm text-red-500 block mt-2">
                  {logoError}
                </span>
              )}
            </div>
          )}
          {!data.isCombined && !data.isToken && (
            <>
              <div className="mt-4">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  placeholder="Ex: Ether"
                  name="name"
                  onChange={handleChange}
                  value={data.name || ""}
                  required
                />
              </div>
              <div className="mt-4">
                <label htmlFor="">Symbol</label>
                <input
                  type="text"
                  placeholder="Ex: ETH"
                  name="symbol"
                  onChange={handleChange}
                  value={data.symbol || ""}
                  required
                />
              </div>
            </>
          )}

          {error && (
            <span className="block text-sm text-red-500 mt-4">{error}</span>
          )}
        </div>

        <div className="flex items-center gap-[1em] p-5">
          <button
            type="button"
            className="w-full text-[#707275] bg-[#24262d] transition-all hover:bg-transparent border border-transparent hover:border-[#24262d] hover:text-red-500"
            onClick={() => {
              setIsAddCurrencyModalOpen(false);
            }}
          >
            Cancel
          </button>
          <button className="w-full" disabled={isLoading}>
            Add Currency
          </button>
        </div>
      </form>
    </div>
  );
}
