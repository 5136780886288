import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { BsArrowUpCircle } from "react-icons/bs";
import { MdSwapHoriz } from "react-icons/md";
import { RiLuggageDepositLine } from "react-icons/ri";
import { useSelector } from "react-redux";

import axios from "../axios";
import {
    BalancesListCard,
    RecentTransactionsTable,
    TopCard,
    TopSmallCard,
    TransactionsVolumeGraph,
} from "../features/Dashboard";
import { useTotalPrice } from "../hooks";
import { formatBalance } from "../utils";
import { Loader } from "../components";

export default function Dashboard() {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { jwtToken } = useSelector((state) => state.admin);
    const totalFeePrice = useTotalPrice(data?.totalFee, "fee");

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await axios.get("/admin/dashboard", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            setIsLoading(false);
            setData(response.data);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (isLoading)
        return (
            <div className="flex justify-center">
                <Loader />
            </div>
        );

    return (
        <div>
            <div className="grid grid-cols-3 gap-6">
                <TopCard
                    bg="#DC6BAD"
                    icon={<AiOutlineDollarCircle />}
                    title="Users Total Balance"
                    currencies={data?.balances}
                    val="balance"
                />
                <TopCard
                    bg="#8C7AA9"
                    icon={<RiLuggageDepositLine />}
                    title="Money In"
                    currencies={data?.moneyIn}
                    val="amount"
                />
                <TopCard
                    bg="#7192BE"
                    icon={<BsArrowUpCircle />}
                    title="Money Out"
                    currencies={data?.moneyOut}
                    val="amount"
                />
            </div>

            <div className="grid grid-cols-4 gap-6 rounded mt-6">
                <TopSmallCard
                    icon={<MdSwapHoriz />}
                    title="Total Transactions"
                    value={data?.totalTransactions}
                />
                <TopSmallCard
                    icon={<MdSwapHoriz />}
                    title="Completed Transactions"
                    value={data?.totalCompletedTransactions}
                />
                <TopSmallCard
                    icon={<MdSwapHoriz />}
                    title="Total Users"
                    value={data?.totalUsers}
                />
                <TopSmallCard
                    icon={<MdSwapHoriz />}
                    title="Total Fee Collected"
                    value={`$ ${formatBalance(totalFeePrice)}`}
                />
            </div>

            <div className="grid grid-cols-[65%_auto] gap-6 mt-6">
                <div>
                    <TransactionsVolumeGraph />
                </div>
                <div>
                    <BalancesListCard balances={data?.balances} />
                </div>
            </div>

            <div className="mt-6">
                <RecentTransactionsTable />
            </div>
        </div>
    );
}
