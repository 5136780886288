import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    prices: [],
    isPriceLoading: true,
    currencies: [],
    totalCurrencies: 0,
    activeCurrencies: 0,
    totalAssets: 0,
    totalTokens: 0,
    networks: [],
};

export const currenciesSlice = createSlice({
    name: "currencies",
    initialState,
    reducers: {
        setPrices: (state, action) => {
            state.prices = action.payload;
            state.isPriceLoading = false;
        },
        setCurrencyDetails: (state, action) => {
            state.currencies = action.payload?.currencies;
            state.totalCurrencies = action.payload?.totalCurrencies;
            state.activeCurrencies = action.payload?.activeCurrencies;
            state.totalAssets = action.payload?.totalAssets;
            state.totalTokens = action.payload?.totalTokens;
        },
        addNewCurrency: (state, action) => {
            const objIndex = state.currencies?.findIndex(
                (obj) => obj?._id === action.payload?._id
            );
            if (objIndex !== -1) {
                if (state.currencies[objIndex]?.assets) {
                    state.currencies[objIndex]?.assets?.push(
                        action.payload?.assets[0]
                    );
                } else {
                    state.currencies[objIndex].assets = [
                        action.payload?.assets[0],
                    ];
                }
                if (action.payload?.assets[0]?.isToken) {
                    state.totalTokens += 1;
                }
            } else {
                state.currencies?.unshift(action.payload);
                state.totalCurrencies += 1;
                state.activeCurrencies += 1;
            }
            state.totalAssets += 1;
        },
    },
});

export const { setPrices, setCurrencyDetails, addNewCurrency } =
    currenciesSlice.actions;

export default currenciesSlice.reducer;
