import React, { useEffect, useState } from "react";
import axios from "../axios";
import { useSelector } from "react-redux";
import { formatBalance } from "../utils";
import BtnLoader from "../components/BtnLoader";
import { IoMdClose } from "react-icons/io";
function AdmInternalTransactionPage() {
  const { jwtToken } = useSelector((state) => state.admin);

  const [totalSolana, setTotalSolana] = useState(0);
  const [totalEthereum, setTotalEthereum] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [data, setData] = useState({
    email: "",
    isSolana: false,
    isEthereum: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState("");

  const fetchBalances = async () => {
    try {
      const res = await axios.get("/admin/internal/all-balance", {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });

      setTotalSolana(res?.data?.solanaTotal);
      setTotalEthereum(res.data?.ethereumTotal);
    } catch (error) {
      console.log(error);
    }
  };

  const transferAllBalnces = async () => {
    try {
      setIsLoading(true);

      const res = await axios.post("/admin/internal/transfer", data, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setIsLoading(false);
      setErr("");
      setData({
        email: "",
        isEthereum: false,
        isSolana: false,
      });
      setConfirmModal(false);
    } catch (error) {
      setErr(error.response.data.error || "somthing went wrong");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBalances();
  }, []);

  return (
    <div>
      <div className="grid md:grid-cols-2 gap-6">
        <div className="bg-[#1a1c23] rounded text-center p-4">
          {/* <span className="text-[35px] flex items-center justify-center">
            {icon}
            </span> */}
          <h3 className="font-medium text-[14px] mt-2">Solana</h3>
          <span className="font-bold text-2xl block mt-2">
            $ {formatBalance(totalSolana)}
          </span>
        </div>
        <div className="bg-[#1a1c23] rounded text-center p-4">
          {/* <span className="text-[35px] flex items-center justify-center">
            {icon}
            </span> */}
          <h3 className="font-medium text-[14px] mt-2">Ethereum</h3>
          <span className="font-bold text-2xl block mt-2">
            $ {formatBalance(totalEthereum)}
          </span>
        </div>
      </div>

      <div className="py-20 flex justify-center ">
        <div>
          <h2 className="font-semibold text-xl text-blue-600">
            Consolidate All Wallet Balances
          </h2>

          <div className="max-w-3xl pt-2">
            <h1 className="text-darkGrayColor">
              This page allows you to manually transfer all user Solana and
              Ethereum balances to the hot wallet. Review the balances below and
              initiate the transfer when ready. Ensure accuracy before
              proceeding.
            </h1>
          </div>

          <div className="flex justify-center py-10">
            <button
              className="w-44"
              onClick={() => {
                setConfirmModal(true);
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>

      <div>
        {confirmModal && (
          <div
            className={
              "fixed top-0 left-0 bg-[#000] w-full h-full z-10 flex items-center justify-center transition-all " +
              (confirmModal ? "visible bg-[#0004]" : "hidden bg-[#0000]")
            }
          >
            <div className="w-96 min-h-max h-auto bg-darkCardBgColor p-1">
              <div className="flex justify-end ">
                <h1
                  className="text-xl cursor-pointer "
                  onClick={() => {
                    setConfirmModal(false);
                  }}
                >
                  <IoMdClose />
                </h1>
              </div>
              <div className="p-5">
                <div>
                  <h1 className="text-lg font-semibold">
                    Verify your email address to proceed with admin confirmation
                  </h1>
                  <p className="text-sm text-gray-600 mt-2">
                    Enter the email address associated with your admin account.
                    We will use this to confirm your identity and ensure secure
                    access.
                  </p>
                </div>

                {err && (
                  <div className="pt-2">
                    <p className="text-red-500 ">{err}</p>
                  </div>
                )}

                <div className="py-3">
                  <label htmlFor="">Email Address</label>
                  <input
                    name="email"
                    type="email"
                    onChange={(e) => {
                      setData({
                        ...data,
                        [e.target.name]: e.target.value,
                      });
                    }}
                    className="w-full h-10"
                  />
                </div>
                <div className="py-3">
                  <div className="flex gap-4">
                    <div className="flex gap-1">
                      <div className="">
                        <input
                          type="checkbox"
                          className="w-4 h-4"
                          name="isSolana"
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.checked,
                            });
                          }}
                        />
                      </div>
                      <label htmlFor="">Solana</label>
                    </div>
                    <div className="flex gap-1">
                      <div className="">
                        <input
                          type="checkbox"
                          className="w-4 h-4"
                          name="isEthereum"
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.checked,
                            });
                          }}
                        />
                      </div>
                      <label htmlFor="">Ethereum</label>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center pt-2">
                  {isLoading ? (
                    <button disabled={true} className="w-full">
                      <BtnLoader />
                    </button>
                  ) : (
                    <button
                      disabled={data.email === "" || !data.email}
                      className="w-full"
                      onClick={() => {
                        transferAllBalnces();
                      }}
                    >
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdmInternalTransactionPage;
