import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function CountriesTable() {
    const { countries } = useSelector((state) => state.countries);
    const navigate = useNavigate();

    return (
        <div className="overflow-x-auto border border-[#333] rounded">
            <table className="w-full bg-darkCardBgColor text-darkGrayColor text-[14px]">
                <thead className="text-left">
                    <tr>
                        <th className="font-[600] px-4 py-3">Isocode</th>
                        <th className="font-[600] px-4 py-3">County</th>
                        <th className="font-[600] px-4 py-3">phonecode</th>
                        <th className="font-[600] px-4 py-3">Active</th>
                    </tr>
                </thead>
                <tbody>
                    {countries?.map((country, index) => {
                        return (
                            <tr
                                key={index}
                                className="border-t border-[#24262d] cursor-pointer hover:bg-[#22252b]"
                                onClick={() => navigate(`states/${country?.isocode}`)}
                            >
                                <td className="px-4 py-3">
                                    {country?.isocode}
                                </td>
                                <td className="px-4 py-3">
                                    <div className="flex items-center gap-[12px]">
                                        <div className="w-[40px] min-w-[30px] overflow-hidden">
                                            <img
                                                src={country?.flag}
                                                alt=""
                                                className="w-[100%] h-[100%] object-cover"
                                            />
                                        </div>
                                        <div>
                                            <span className="block">
                                                {country?.countryName}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-4 py-3">
                                    {country?.phonecode}
                                </td>
                                <td className="px-4 py-3">
                                    {country?.isActive}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
