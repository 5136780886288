import React, { useEffect, useState } from "react";
import { KycApprovalsTable } from "../features/KycApprovalPage";
import axios from "../axios";
import { useSelector } from "react-redux";

export default function KycApprovalPage() {
  const [kycDetails, setKycDetails] = useState([]);

  const { jwtToken } = useSelector((state) => state.admin);

  const fetchKycDetails = async () => {
    try {
      const res = await axios.get(`/admin/kyc/all`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setKycDetails(res?.data?.KYC);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchKycDetails();
  }, []);

  return (
    <div>
      <div className="mb-5">
        <h1 className="font-[600] text-lg">Kyc Approval</h1>
      </div>

      <div className="flex items-center gap-[10px] bg-darkCardBgColor p-5 rounded mb-6">
        <input type="text" className="h-[45px]" placeholder="Search User..." />
        <button className="w-[200px] h-[45px]">Search</button>
      </div>

      <div>
        <KycApprovalsTable
          kycDetails={kycDetails}
          fetchKycDetails={fetchKycDetails}
        />
      </div>
    </div>
  );
}
