import React from "react";
import Chart from "react-apexcharts";

var data = {
    series: [50, 30, 10, 10],
    options: {
        chart: {
            type: "donut",
            width: "100%",
        },
        labels: ["Windows", "Osx", "Android", "Linux", "ChromeOs"],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 2,
        },
        // colors: ["#0E8A74", "#FB3E7A", "#C8C8C8", "#FB3E7A"],
        legend: {
            position: "bottom",
            show: true,
        },
        responsive: [
            {
                breakpoint: 1600,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        position: "bottom",
                        show: true,
                    },
                },
            },
        ],
    },
};

export default function UsersOsList() {
    return (
        <div className="bg-darkCardBgColor rounded p-5">
            <h2 className="font-medium text-base mb-5">Os List</h2>
            <Chart options={data.options} series={data.series} type="donut" />
        </div>
    );
}
