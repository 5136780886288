import React from "react";

import { formatBalance } from "../../../utils";

export default function SingleUserCurrenyCard({ item }) {
    return (
        <div className="bg-darkCardBgColor flex items-center gap-[10px] rounded py-2 px-4">
            <div className="w-[30px] h-[30px] rounded-full overflow-hidden bg-white">
                <img
                    src={
                        process.env.REACT_APP_SERVER_URL + item?.currency?.logo
                    }
                    alt=""
                    className="w-[100%] h-[100%] object-fit"
                />
            </div>
            <div className="">
                <span className="block text-darkGrayColor text-sm">
                    {item?.currency?.name}
                </span>
                <span className="block font-[600] text-[15px] mt-[2px]">
                    {formatBalance(item?.balance)} {item?.currency?.symbol}
                </span>
            </div>
        </div>
    );
}
