import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";

import axios from "./axios";
import { setPrices } from "./redux/slices/currenciesSlice";
import ThemeRoutes from "./routes/Router";
import { useState } from "react";
import { setAdminAfterReload } from "./redux/slices/adminSlice";
import { MainLoader } from "./components";

export default function App() {
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const { jwtToken } = useSelector((state) => state.admin);

    const fetchAdmin = async () => {
        try {
            const response = await axios.get("/admin/auth/details", {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            dispatch(setAdminAfterReload(response.data));
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const fetchPrices = async () => {
        try {
            const options = {
                method: "GET",
                url: "https://l4chsalter-alternative-me-crypto-v1.p.rapidapi.com/v1/ticker/",
                params: { limit: "12" },
                headers: {
                    "X-RapidAPI-Key":
                        "1687ad4495msh867a1e0b6e9aecep15601djsn887a5f622916",
                    "X-RapidAPI-Host":
                        "l4chsalter-alternative-me-crypto-v1.p.rapidapi.com",
                },
            };

            const response = await axios.request(options);
            dispatch(setPrices(response.data));
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchAdmin();
    }, []);

    useEffect(() => {
        fetchPrices();
    }, []);

    const routing = useRoutes(ThemeRoutes);
    return isLoading ? <MainLoader /> : routing;
}
