import {
  BsShop,
  BsGrid,
  BsListUl,
  BsPeople,
  BsCompass,
  BsGear,
  BsCurrencyExchange,
} from "react-icons/bs";
import { BiLink } from "react-icons/bi";
import { MdOutlineCurrencyExchange } from "react-icons/md";
import { AiOutlineTransaction } from "react-icons/ai";
const sidebarLinks = [
  {
    name: "Dashboard",
    to: "/",
    icon: <BsGrid />,
    role: "admin",
  },
  {
    name: "Transactions",
    to: "/transactions",
    icon: <BsShop />,
    role: "admin",
  },
  {
    name: "Networks",
    to: "/networks",
    icon: <BsListUl />,
    role: "admin",
  },
  {
    name: "Currencies",
    to: "/currencies",
    icon: <BsCurrencyExchange />,
    role: "admin",
  },
  {
    name: "Users",
    to: "/users",
    icon: <BsPeople />,
    role: "admin",
  },
  {
    name: "KYC Approval",
    to: "/kyc-approval",
    icon: <BsCompass />,
    role: "admin",
  },
  {
    name: "Countries",
    to: "/countries",
    icon: <BsCompass />,
    role: "admin",
  },
  {
    name: "Admins",
    to: "/admins",
    icon: <BsCompass />,
    role: "super-admin",
  },
  {
    name: "Reports",
    to: "/reports",
    icon: <BsCompass />,
    role: "admin",
  },
  {
    name: "Presale",
    to: "/presale",
    icon: <BiLink />,
    role: "admin",
  },
  {
    name: "Internal Transactions",
    to: "/internal-transactions",
    icon: <AiOutlineTransaction />,
    role: "super-admin",
  },
  {
    name: "Settings",
    to: "/settings",
    icon: <BsGear />,
    role: "admin",
  },
];

export default sidebarLinks;
