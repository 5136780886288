import React from "react";

export default function UsersCountryList({ data }) {
    return (
        <div className="bg-darkCardBgColor rounded p-5 h-full">
            <h2 className="font-medium text-base mb-6">Country</h2>
            {data?.map((item, index) => {
                return (
                    <div
                        key={index}
                        className="flex flex-wrap items-center justify-between mb-5 last:mb-0 gap-[10px]"
                    >
                        <div className="flex flex-wrap items-center gap-[12px]">
                            <div className="w-[40px] overflow-hidden">
                                <img src={item?.country?.flag} alt="" />
                            </div>
                            <div>
                                <span className="block text-darkTextColor font-medium">
                                    {item?.country?.countryName}
                                </span>
                            </div>
                        </div>
                        <div className="text-right">
                            <span className="block font-medium text-[14px] text-[#ccc]">
                                {item?.percent}%
                            </span>
                            <span className="block text-sm text-darkGrayColor">
                                {item?.users}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
