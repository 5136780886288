import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

export default function TransactionsCurrencyGraph({ txnData }) {
    const [data, setData] = useState({
        series: [],
        options: {
            chart: {
                type: "donut",
                width: "100%",
            },
            labels: [],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                width: 2,
            },
            legend: {
                position: "bottom",
                show: true,
            },
            responsive: [
                {
                    breakpoint: 1600,
                    options: {
                        chart: {
                            width: "100%",
                        },
                        legend: {
                            position: "bottom",
                            show: true,
                        },
                    },
                },
            ],
        },
    });

    console.log(data);

    useEffect(() => {
        setData((prev) => {
            return {
                ...prev,
                series: txnData?.map((dt) => dt?.totalAmount),
                options: {
                    ...prev.options,
                    labels: txnData
                        ?.map((dt) => dt?.currency?.symbol)
                        .filter((dt) => dt !== undefined),
                },
            };
        });
    }, [txnData]);

    return (
        <div className="bg-darkCardBgColor rounded p-5">
            <h2 className="font-medium text-base">Currency List</h2>
            <div className="mt-[3em]">
                {data.series?.length > 0 && (
                    <Chart
                        options={data?.options}
                        series={data?.series}
                        type="donut"
                    />
                )}
            </div>
        </div>
    );
}
