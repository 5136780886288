import React from "react";
import { useState } from "react";
import axios from "../axios";
import { useDispatch, useSelector } from "react-redux";
import { setAdmin } from "../redux/slices/adminSlice";

export default function LoginPage() {
    const [data, setData] = useState({
        email: "",
        password: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const { jwtToken } = useSelector((state) => state.admin);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            const response = await axios.post("/admin/auth/login", data, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            dispatch(setAdmin(response.data));
            setIsLoading(false);
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen p-5">
            <div className="w-full max-w-[350px] bg-darkCardBgColor p-5">
                <h1 className="font-[600] text-lg text-center mb-6">Login</h1>
                <form action="" onSubmit={handleSubmit}>
                    <div>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={data.email || ""}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mt-4">
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={data.password || ""}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {error && (
                        <span className="block text-sm text-red-500 mt-3">
                            {error}
                        </span>
                    )}
                    <button className="mt-5 w-full">Login</button>
                </form>
            </div>
        </div>
    );
}
