import React, { useRef } from "react";
import { useState } from "react";
import { IoClose } from "react-icons/io5";

import axios from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { addNewCountry } from "../../../redux/slices/countriesSlice";
import { useHandleClickOutside } from "../../../hooks";

export default function AddCountryModal({
    isCountryModalOpen,
    setIsCountryModalOpen,
}) {
    const [data, setData] = useState({
        countryName: "",
        isocode: "",
        phonecode: "",
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { jwtToken } = useSelector((state) => state.admin);
    const wrapperRef = useRef();
    const dispatch = useDispatch();
    useHandleClickOutside(wrapperRef, () => setIsCountryModalOpen(false));

    const handleChange = (e) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setError("");
            setIsLoading(true);

            const response = await axios.post(
                "/admin/countries/new",
                {
                    ...data,
                    flag: `https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${data.isocode?.toLowerCase()}.svg`,
                },
                { headers: { Authorization: `Bearer ${jwtToken}` } }
            );
            dispatch(addNewCountry(response.data));
            setIsCountryModalOpen(false);
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div
            className={
                "fixed top-0 left-0 bg-[#000] w-full h-full z-10 transition-all " +
                (isCountryModalOpen
                    ? "visible bg-[#0004]"
                    : "hidden bg-[#0000]")
            }
        >
            <form
                className={
                    "fixed top-0 right-0 flex flex-col bg-darkCardBgColor w-[580px] max-w-[100%] h-full z-10 flex-colum transition-all " +
                    (isCountryModalOpen
                        ? "translate-x-0"
                        : "translate-x-[100%]")
                }
                onSubmit={handleSubmit}
                ref={wrapperRef}
            >
                <div className="flex items-center justify-between p-5">
                    <div className="text-[#d5d6d7]">
                        <h4 className="font-[600] text-lg">Add Country</h4>
                        <p className="text-sm text-darkGrayColor mt-[2px]">
                            Add country and necessary information here
                        </p>
                    </div>
                    <button
                        type="button"
                        className="bg-[#fff] text-[#f05252] w-[25px] h-[25px] rounded-full text-base flex items-center justify-center p-0 hover:bg-[#ffe1e1]"
                        onClick={() => {
                            setIsCountryModalOpen(false);
                        }}
                    >
                        <IoClose />
                    </button>
                </div>
                <div className="flex-1 overflow-y-auto p-5 bg-[#24262d]">
                    <div className="">
                        <label htmlFor="">Country Name</label>
                        <input
                            type="text"
                            placeholder="Ex: India"
                            name="countryName"
                            value={data.countryName || ""}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">ISO Code</label>
                        <input
                            type="text"
                            placeholder="Ex: IN"
                            name="isocode"
                            onChange={handleChange}
                            value={data.isocode || ""}
                            required
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">Phone Code</label>
                        <input
                            type="number"
                            placeholder="Ex: +91"
                            name="phonecode"
                            onChange={handleChange}
                            value={data.phonecode || ""}
                            required
                        />
                    </div>
                    <div className="mt-4">
                        {data?.isocode && (
                            <div className="w-[50px] mt-3">
                                <img
                                    src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${data.isocode?.toLowerCase()}.svg`}
                                    alt=""
                                    className="w-full h-full"
                                />
                            </div>
                        )}
                    </div>

                    <span className="block text-sm text-red-500 mt-4">
                        {error}
                    </span>
                </div>

                <div className="flex items-center gap-[1em] p-5">
                    <button
                        type="button"
                        className="w-full text-[#707275] bg-[#24262d] transition-all hover:bg-transparent border border-transparent hover:border-[#24262d] hover:text-red-500"
                        onClick={() => {
                            setIsCountryModalOpen(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button className="w-full" disabled={isLoading}>
                        Add Country
                    </button>
                </div>
            </form>
        </div>
    );
}
