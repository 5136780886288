import React from "react";

export default function StatesTable({ states }) {
    return (
        <div className="overflow-x-auto border border-[#333] rounded">
            <table className="w-full bg-darkCardBgColor text-darkGrayColor text-[14px]">
                <thead className="text-left">
                    <tr>
                        <th className="font-[600] px-4 py-3">Isocode</th>
                        <th className="font-[600] px-4 py-3">State Name</th>
                        <th className="font-[600] px-4 py-3">Latitude</th>
                        <th className="font-[600] px-4 py-3">Longitude</th>
                    </tr>
                </thead>
                <tbody>
                    {states?.map((state, index) => {
                        return (
                            <tr
                                key={index}
                                className="border-t border-[#24262d]"
                            >
                                <td className="px-4 py-3">{state?.isocode}</td>
                                <td className="px-4 py-3">
                                    {state?.stateName}
                                </td>
                                <td className="px-4 py-3">
                                    {state?.latitude || "N/A"}
                                </td>
                                <td className="px-4 py-3">
                                    {state?.longitude || "N/A"}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
