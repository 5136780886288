import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import KycSingleDetailPage from "../../../pages/KycSingleDetailPage";
import KycSinglePage from "./KycSinglePage";

export default function KycApprovalsTable({ kycDetails, fetchKycDetails }) {
  const navigate = useNavigate();

  const [isModal, setIsModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const handleModal = (id) => {
    setSelectedId(id);
    setIsModal(true);
  };

  return (
    <div className="overflow-x-auto border border-[#333] rounded">
      <table className="w-full bg-darkCardBgColor text-darkGrayColor text-[14px]">
        <thead className="text-left">
          <tr>
            <th className="font-[600] px-4 py-3">Id.</th>
            <th className="font-[600] px-4 py-3">Name</th>
            <th className="font-[600] px-4 py-3">Type</th>
            <th className="font-[600] px-4 py-3">Country</th>
            <th className="font-[600] px-4 py-3">Company UIN</th>
            <th className="font-[600] px-4 py-3">Status</th>
          </tr>
        </thead>
        <tbody>
          {kycDetails?.map((ele, index) => {
            return (
              <tr
                key={index}
                className="border-t border-[#24262d] cursor-pointer hover:bg-gray-900"
                onClick={() => {
                  handleModal(ele?._id);
                }}
              >
                <td className="px-4 py-3">{index + 1}</td>
                <td className="px-4 py-3">
                  <div className="flex items-center gap-[12px]">
                    <div className="w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-full overflow-hidden">
                      <img
                        src={ele?.avatar ? ele?.avatar : ""}
                        alt="avatar"
                        className="w-[100%] h-[100%] object-cover"
                      />
                    </div>
                    <div>
                      <span className="block">{ele?.user?.name}</span>
                      <span className="block">@{ele?.user?.username}</span>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-3">{ele?.user?.type}</td>
                <td className="px-4 py-3">{ele?.country}</td>
                <td className="px-4 py-3">{ele?.company_UIN}</td>
                <td className="px-4 py-3">
                  <h1
                    className={`${
                      ele?.user?.kycStatus === "pending"
                        ? "bg-orange-200 text-orange-500"
                        : ele?.user?.kycStatus === "rejected"
                        ? "bg-red-200 text-red-500"
                        : "bg-green-200 text-green-500"
                    }  text-center font-bold p-1 w-24 rounded-xl`}
                  >
                    {" "}
                    {ele?.user?.kycStatus}
                  </h1>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {isModal && (
        <div>
          <KycSinglePage
            setIsModal={setIsModal}
            id={selectedId}
            fetchKycDetails={fetchKycDetails}
          />
        </div>
      )}
    </div>
  );
}
